<template>
  <v-card flat>
    <ItineraryStepCard
      v-for="(step, index) in itinerary.steps"
      :key="'itinerary-step-' + index"
      :step="step"
      :firstStep="index === 0"
      @remove="emitRemoveStep"
    />
    <ItineraryDestinationCard :destination="itinerary.destination" />
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import ItineraryDestinationCard from "./ItineraryDestinationCard.vue";
import ItineraryStepCard from "./ItineraryStepCard.vue";
import Itinerary from "@/logic/classes/trip_classes/itinerary";

export default Vue.extend({
  name: "ItineraryMain",
  props: {
    itinerary: {
      type: Object as PropType<Itinerary>,
      required: true,
    },
  },
  components: {
    ItineraryDestinationCard,
    ItineraryStepCard,
  },
  methods: {
    emitRemoveStep(locationCDBID: string) {
      this.$emit("removeStep", locationCDBID);
    },
  },
});
</script>

<template>
  <l-marker
    :lat-lng="[
      locationData.coordinates.latitude,
      locationData.coordinates.longitude,
    ]"
    @click="pinClick"
  >
    <l-icon>
      <div style="position: relative">
        <div
          v-if="showStar"
          class="rounded-circle white black--text"
          style="
            position: absolute;
            left: -4.5px;
            top: -28px;
            offset-anchor: bottom center;
            width: 20px;
            height: 20px;
          "
        ></div>
        <v-icon
          style="
            position: absolute;
            left: calc(50% - 20px);
            top: -31px;
            offset-anchor: bottom center;
          "
          x-large
          color="orange"
        >
          {{ showStar ? "mdi-map-marker-star" : "mdi-map-marker" }}
        </v-icon>
        <div
          v-if="!showStar"
          class="rounded-circle white black--text"
          style="
            position: absolute;
            left: -4.5px;
            top: -25px;
            offset-anchor: bottom center;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ff9800;
          "
        >
          <span>{{ number }}</span>
        </div>
      </div>
    </l-icon>
  </l-marker>
</template>

<script lang="ts">
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import {
  MutationTypes,
  TertiaryDialogContent,
} from "@/logic/store/store_types";
import Vue, { PropType } from "vue";
import { LMarker, LIcon } from "vue2-leaflet";

/** `Vue Leaflet Component:` renders map pins for locations, intended for use in the `MapPanel` component.
 *
 * @props `locationData` - the whole `TripLocation` class object for an individual trip location.
 */
export default Vue.extend({
  name: "LocationPin",
  props: {
    locationData: Object as PropType<TripLocation>,
    number: Number,
    showStar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LMarker,
    LIcon,
  },
  methods: {
    pinClick() {
      this.$store.commit(
        MutationTypes.setSelectedLocation,
        this.locationData.local_id
      );
      this.$store.commit(
        MutationTypes.setTertiaryDialogContent,
        TertiaryDialogContent.LOCATION_DETAILS
      );
    },
  },
});
</script>

import { settings } from "@/logic/types/directus_types";
import {
  directusCreateOne,
  directusReadByQuery,
  directusUpdateOne,
} from "./helperFunctions";
import { PartialObj } from "@/logic/types/generic_types";
import { CollectionNames } from "../../clients/directusClient";

const collectionName: CollectionNames = "Optimiser_Settings";

/**
 * Updates an existing directus `Optimiser_Settings` collection record.
 *
 * @param recordId the directus `Optimiser_Settings` collection record id.
 * @param dataToUpdate partial object to update
 * @returns the updated record if successful, undefined if not.
 */
export async function updateSettingsRecord(
  recordId: string | number,
  dataToUpdate: PartialObj<settings>
): Promise<settings | undefined> {
  return await directusUpdateOne<settings>(
    collectionName,
    recordId,
    dataToUpdate
  );
}

/**
 * Fetches the users optimiser settings object if there is one alternatively for first time users
 * creates a record and returns that with the default values.
 *
 * @param userID the user directus user id
 * @returns a `settings` object if successful see types for details. or undefined if an error occurs.
 */
export async function fetchSettings(
  userID: string
): Promise<settings | undefined> {
  // attempt to fetch existing record
  const fetchReqRes: settings[] | undefined =
    await directusReadByQuery<settings>(collectionName, {
      filter: { user_created: { _eq: userID } },
    });

  // check if a record was retrieved
  if (fetchReqRes?.length) {
    return fetchReqRes[0];
  }

  // create new record, ASSUMES if error no record exists.
  const createReqRes = await directusCreateOne<settings>(collectionName, {});
  if (createReqRes) return createReqRes;
}

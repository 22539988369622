<template>
  <v-card
    :class="
      isShowing
        ? 'rounded-lg d-flex justify-space-between align-center px-4 mb-2 pwt-selected-stored-trip'
        : 'rounded-lg d-flex justify-space-between align-center px-4 mb-2 grey lighten-3'
    "
    width="100%"
    flat
    @click.stop="toggleShowRoute"
  >
    <div>
      <v-btn icon>
        <v-icon color="primary" large> mdi-map </v-icon>
      </v-btn>
    </div>
    <div>
      <v-card-text v-if="planning" class="font-weight-medium text-body-2">
        <v-progress-circular
          indeterminate
          color="primary"
          size="30"
        ></v-progress-circular>
      </v-card-text>
      <v-card-text
        class="font-weight-medium text-body-2"
        v-else-if="tripDetail.name"
      >
        {{ tripDetail.name }}
      </v-card-text>
      <v-card-text class="font-weight-medium text-body-2" v-else>
        Trip to
        {{ tripDetail.locations[tripDetail.locations.length - 1].address }}
      </v-card-text>
    </div>
    <v-spacer v-if="tripDetail.localId === 'demo-trip'" />
    <div v-else class="d-flex">
      <v-btn icon @click.stop="save" v-if="!tripDetail.directusId">
        <v-progress-circular
          indeterminate
          color="primary"
          size="30"
          v-if="savingStatus === 'PROCESSING'"
        ></v-progress-circular>
        <v-icon color="success" large v-else-if="savingStatus === 'SUCCESS'">
          mdi-check</v-icon
        >
        <v-icon color="error" large v-else-if="savingStatus === 'FAILED'">
          mdi-close</v-icon
        >
        <v-icon color="primary" large v-else> mdi-content-save</v-icon>
      </v-btn>
      <v-btn icon @click.stop="openDeleteConfirmation">
        <v-icon color="error" large> mdi-trash-can </v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="showDialog" width="300">
      <v-card class="rounded-lg">
        <v-card-title> Name it and save it </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="inputtedName"
            label="name"
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <ElevatedBtn @click="nameAndSave"> Save </ElevatedBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script lang="ts">
import { ActionTypes } from "@/logic/store/store_types";
import {
  MutationTypes,
  SecondaryDialogContent,
  State,
} from "@/logic/store/store_types";
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
import Trip from "@/logic/classes/trip_classes/trip";

/** Vue Component: renders list tile cards for stored trips. To be used in the TripsContent page */
export default Vue.extend({
  name: "StoredTripCard",
  components: { ElevatedBtn },
  props: {
    tripDetail: Object as PropType<Trip>,
  },
  data() {
    return {
      showDialog: false,
      inputtedName: "",
    };
  },
  computed: {
    isShowing(): boolean {
      return (
        (this.$store.state as State).selectedTrip === this.tripDetail.local_id
      );
    },
    ...mapState({
      planning: (state: unknown) => (state as State).routePlanningFlag,
      savingStatus: (state: unknown) => (state as State).tripSavingStatus,
    }),
  },
  methods: {
    toggleShowRoute() {
      // check if attempting to display or hide
      if (!this.isShowing) {
        // attempting to display case

        this.$store.dispatch(ActionTypes.showTrip, this.tripDetail);
      } else {
        // attempting to hide case

        // commit clear state to `uiStore`.
        this.$store.commit(MutationTypes.setSelectedTrip, undefined);
        this.$store.commit(MutationTypes.setPannedCenter, undefined);
      }
    },
    openDeleteConfirmation() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.STORED_TRIP_DELETE_CONFIRMATION
      );
      this.$store.commit(
        MutationTypes.setQueuedTripForDelete,
        this.tripDetail.localId
      );
    },
    save() {
      // check if trip has name
      if (!this.tripDetail.name) {
        this.showDialog = true;
        return;
      }
      // Notify analytics server
      // eslint-disable-next-line
      // @ts-ignore
      Vue.prototype.$Countly.q.push([
        "add_event",
        {
          key: "Trip Saved",
          count: 1,
        },
      ]);
      this.$store.dispatch(ActionTypes.saveTrip, this.tripDetail.localId);
    },
    nameAndSave() {
      if (this.inputtedName) {
        this.$store.dispatch(ActionTypes.nameAndSaveTrip, {
          tripID: this.tripDetail.localId,
          name: this.inputtedName,
        });
      } else {
        this.$store.dispatch(ActionTypes.saveTrip, this.tripDetail.localId);
      }
      this.showDialog = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.tripDetail.name) {
        // set initial suggested name if not named
        this.inputtedName = `Trip to ${
          this.tripDetail.locations[this.tripDetail.locations.length - 1]
            .address
        }`;
      }
    });
  },
});
</script>
<style scoped>
.pwt-selected-stored-trip {
  border: 2px solid var(--v-primary-base) !important;
}
</style>

<template>
  <v-dialog
    :value="activator"
    :width="$vuetify.breakpoint.mdAndUp ? '75%' : '100%'"
    content-class="rounded-lg background pwt-scrollbar-styles"
    class="pwt-scroll-styles"
    scrollable
    style="z-index: 1200"
    @click:outside="emitClose()"
    @keydown="keyDown($event)"
  >
    <!-- must have vh for height to work with v-dialog having no exposed height prop. -->
    <v-card
      style="height: 80vh"
      :class="
        $vuetify.breakpoint.xs
          ? 'background overflow-hidden'
          : 'rounded-lg background overflow-hidden'
      "
      flat
    >
      <div
        id="dashboard-scroll-container"
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto"
      >
        <!-- second card to counteract miss-behaving v-dialog overflow due to vh needed earlier. -->
        <v-card
          class="pl-5 pr-10 pt-5 background"
          flat
          style="position: relative"
        >
          <!-- tabs -->
          <v-tabs
            vertical
            v-model="tab"
            style="position: fixed; top: calc(10vh + 40px); width: 200px"
            hide-slider
            @change="resetScrollPosition"
          >
            <!-- tab 0 -->
            <v-tab class="text-none background">
              Home <v-spacer></v-spacer>
            </v-tab>
            <!-- tab 1 -->
            <v-tab class="text-none background">
              Account <v-spacer></v-spacer>
            </v-tab>
            <v-expansion-panels v-model="expand" accordion flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header class="v-tab text-none background">
                  Vehicles
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <!-- tab 4, note expansion panel changes the order as not initially in DOM on component creation -->
                  <v-tab
                    class="text-none"
                    :class="tab === 4 ? 'primary--text' : ''"
                  >
                    Vehicle details <v-spacer></v-spacer>
                  </v-tab>
                  <!-- tab 5, note expansion panel changes the order as not initially in DOM on component creation -->
                  <v-tab
                    class="text-none"
                    :class="tab === 5 ? 'primary--text' : ''"
                  >
                    Fleet vehicles <v-spacer></v-spacer>
                  </v-tab>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- tab 2 -->
            <v-tab class="text-none background">
              Locations manager <v-spacer></v-spacer>
            </v-tab>
            <!-- tab 3 -->
            <v-tab class="text-none background">
              Trip planning defaults <v-spacer></v-spacer>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            style="
              margin-left: 200px;
              border-left: 2px solid rgba(218, 218, 218, 0.5) !important;
            "
            class="py-5 pl-5 background"
          >
            <!-- tab 0 -->
            <v-tab-item>
              <DashboardMain
                @view-vehicle="viewVehicle"
                @view-account="viewAccount"
                @view-trip="emitClose"
                @view-all-vehicles="viewAllVehicles"
                @view-locations="viewLocationsManager"
                @view-defaults="viewDefaults"
                @logout="emitClose"
              />
            </v-tab-item>
            <!-- tab 1 -->
            <v-tab-item>
              <DashboardBreadcrumbs
                :items="[
                  { text: 'Dashboard' },
                  { text: 'Manage account', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Dashboard' })"
              />
              <AccountMain @view-vehicle="viewVehicle" />
            </v-tab-item>
            <!-- tab 2 -->
            <v-tab-item>
              <DashboardBreadcrumbs
                :items="[
                  { text: 'Dashboard' },
                  { text: 'Location manager', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Dashboard' })"
              />
              <FavLocationsMain />
            </v-tab-item>
            <!-- tab 3 -->
            <v-tab-item>
              <DashboardBreadcrumbs
                :items="[
                  { text: 'Dashboard' },
                  { text: 'Edit trip planning presets', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Dashboard' })"
              />
              <TripPlanningDefaults />
            </v-tab-item>
            <!-- tab 4 -->
            <v-tab-item>
              <DashboardBreadcrumbs
                :items="[
                  { text: 'Dashboard' },
                  { text: 'Fleet vehicles' },
                  { text: 'Vehicle details', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Fleet vehicles' })"
              />
              <VehicleDetails :vehicle="vehicleData" :isSelected="isSelected" />
            </v-tab-item>
            <!-- tab 5 -->
            <v-tab-item>
              <DashboardBreadcrumbs
                :items="[
                  { text: 'Dashboard' },
                  { text: 'Fleet vehicles', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Dashboard' })"
              />
              <VehicleList @view-vehicle="viewVehicle" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
      <!-- floating close button -->
      <v-btn
        icon
        @click.stop="emitClose()"
        style="position: absolute; top: 8px; right: 12px"
      >
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import AccountMain from "./AccountMain.vue";
import FavLocationsMain from "../favourite-locations/info-panel-content/FavLocationsMain.vue";
import TripPlanningDefaults from "./TripPlanningDefaults.vue";
import VehicleDetails from "../vehicles/VehicleDetails.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { GettersTypes, State } from "@/logic/store/store_types";
import VehicleList from "../vehicles/VehicleList.vue";
import DashboardMain from "./DashboardMain.vue";
import DashboardBreadcrumbs, {
  BreadcrumbsItem,
} from "./DashboardBreadcrumbs.vue";

export default Vue.extend({
  name: "DashboardDialog",
  props: {
    activator: {
      type: Boolean,
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.emitClose();
    },
    /**
     * Sets the current vehicle ID and navigates to the vehicle details tab.
     *
     * @param {string|undefined} vehicleId - The ID of the vehicle to view.
     * @return {void}
     */
    viewVehicle(vehicleId: string | undefined) {
      this.vehicleId = vehicleId;
      this.$nextTick(() => {
        this.expand = 0; // wrapped in next tick to avoid race condition of if expand being in the DOM at this point.
        this.$nextTick(() => {
          this.tab = 4; // wrapped in next tick to avoid race condition of no tab 3 being in the DOM at this point.
          this.resetScrollPosition();
        });
      });
    },
    viewAllVehicles() {
      this.$nextTick(() => {
        this.expand = 0;
        this.$nextTick(() => {
          this.tab = 5; // wrapped in next tick to avoid race condition of no tab 5 being in the DOM at this point.
          this.resetScrollPosition();
        });
      });
    },
    viewAccount() {
      this.$nextTick(() => {
        this.tab = 1; // wrapped in next tick to avoid race condition of no tab 1 being in the DOM at this point.
        this.resetScrollPosition();
      });
    },
    viewLocationsManager() {
      this.$nextTick(() => {
        this.tab = 2; // wrapped in next tick to avoid race condition of no tab 2 being in the DOM at this point.
        this.resetScrollPosition();
      });
    },
    viewDashboard() {
      this.tab = 0;
      this.resetScrollPosition();
    },
    viewDefaults() {
      this.tab = 3;
      this.resetScrollPosition();
    },
    breadcrumbNavigation(item: BreadcrumbsItem) {
      if (item.disabled) return;
      if (item.text === "Dashboard") {
        this.viewDashboard();
      } else if (item.text === "Fleet vehicles") {
        this.viewAllVehicles();
      }
    },
    resetScrollPosition() {
      this.$nextTick(() => {
        const myDiv = document.getElementById("dashboard-scroll-container");
        if (myDiv) myDiv.scrollTop = 0;
      });
    },
  },
  components: {
    AccountMain,
    FavLocationsMain,
    TripPlanningDefaults,
    VehicleDetails,
    VehicleList,
    DashboardMain,
    DashboardBreadcrumbs,
  },
  data() {
    return {
      tab: 0,
      vehicleId: undefined as string | undefined,
      expand: undefined as number | undefined,
    };
  },
  computed: {
    vehicleData(): Vehicle | undefined {
      return this.vehicleId
        ? this.$store.getters[GettersTypes.getVehicleById](this.vehicleId)
        : this.$store.getters[GettersTypes.selectedVehicleData];
    },
    isSelected(): boolean {
      return (
        this.vehicleData?.localId ===
        (this.$store.state as State).selectedVehicle
      );
    },
  },
});
</script>
<style scoped>
.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>

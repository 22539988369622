<template>
  <v-card v-if="firstStep" class="mb-5 rounded-lg">
    <v-card-title style="word-break: normal">
      Depart from
      {{ step.name }}
    </v-card-title>
    <v-card-subtitle> {{ step.addressStr }}</v-card-subtitle>
    <v-card-text>
      <v-slider
        :label="
          'Depart with ' + Math.round(step.chargeBeforeTraveling * 100) + '%'
        "
        :value="Math.round(step.chargeBeforeTraveling * 100)"
        track-color="grey lighten-2"
        readonly
      ></v-slider>
      <ItineraryStepTravelDisplay
        :startingSoC="step.chargeBeforeTraveling"
        :endingSoC="step.chargeAfterTraveling"
        :chargeUsedTraveling="step.chargeUsedTraveling"
        :drivingDistance="step.drivingDistance"
        :ferryTime="step.ferryTime"
        :travelTime="step.travelTime"
      />
    </v-card-text>
  </v-card>
  <v-card
    v-else-if="step.locationCDBID"
    class="mb-5 ml-10 rounded-lg"
    style="position: relative"
  >
    <v-row align="start">
      <v-icon color="primary" x-large class="pt-5 pl-4">
        mdi-map-marker
      </v-icon>
      <v-card flat style="max-width: calc(100% - 64px)">
        <v-card-title style="word-break: normal" class="pl-0">
          Charge at {{ step.name }}
        </v-card-title>
        <v-card-subtitle class="pl-0">
          Arrive with {{ Math.round(step.chargeBeforeCharging * 100) }}%
        </v-card-subtitle>
      </v-card>
    </v-row>
    <v-card-text>
      <ul style="list-style: none" class="pl-0 mb-3">
        <li>
          <v-icon color="primary" small>mdi-circle-slice-5</v-icon> Charging
          time
          {{ niceDuration(step.chargingTime) }}
        </li>
        <li>
          <v-icon color="primary" small>mdi-currency-usd</v-icon> Charging cost
          ~{{ niceCurrency(step.chargingCost) }}
        </li>
      </ul>
      <v-slider
        :label="
          'Charge to ' + Math.round(step.chargeBeforeTraveling * 100) + '%'
        "
        :value="Math.round(step.chargeBeforeTraveling * 100)"
        track-color="grey lighten-2"
        readonly
      ></v-slider>
      <ItineraryStepTravelDisplay
        :startingSoC="step.chargeBeforeTraveling"
        :endingSoC="step.chargeAfterTraveling"
        :chargeUsedTraveling="step.chargeUsedTraveling"
        :drivingDistance="step.drivingDistance"
        :ferryTime="step.ferryTime"
        :travelTime="step.travelTime"
      />
    </v-card-text>
    <v-btn
      icon
      style="position: absolute; top: 16px; right: 0"
      @click="emitRemove"
      color="error"
    >
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
  </v-card>
  <v-card v-else class="mb-5">
    <v-row align="start">
      <v-icon color="primary" x-large class="pt-5 pl-4">
        mdi-map-marker-outline
      </v-icon>
      <v-card flat style="max-width: calc(100% - 64px)">
        <v-card-title style="word-break: normal" class="pr-0">
          Stop at {{ step.name }}
        </v-card-title>
        <v-card-subtitle class="pr-0">
          <ul style="list-style: none" class="pl-0">
            <li>
              Arrive with {{ Math.round(step.chargeBeforeCharging * 100) }}%
            </li>
            <li>
              Depart with {{ Math.round(step.chargeBeforeTraveling * 100) }}%
            </li>
          </ul>
        </v-card-subtitle>
      </v-card>
    </v-row>
    <v-card-text>
      <ItineraryStepTravelDisplay
        :startingSoC="step.chargeBeforeTraveling"
        :endingSoC="step.chargeAfterTraveling"
        :chargeUsedTraveling="step.chargeUsedTraveling"
        :drivingDistance="step.drivingDistance"
        :ferryTime="step.ferryTime"
        :travelTime="step.travelTime"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ItineraryStep } from "@/logic/classes/trip_classes/itinerary";
import ItineraryStepTravelDisplay from "./ItineraryStepTravelDisplay.vue";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import to2DP from "@/logic/utils/to2DP";

export default Vue.extend({
  name: "ItineraryStepCard",
  props: {
    step: {
      type: Object as PropType<ItineraryStep>,
      required: true,
    },
    firstStep: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItineraryStepTravelDisplay,
  },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    niceCurrency(amount: number): string {
      return "$" + to2DP(amount);
    },
    emitRemove() {
      if (!this.step.locationCDBID) return;
      this.$emit("remove", this.step.locationCDBID as string);
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>

<template>
  <v-card flat class="px-5 pb-5">
    <v-row no-gutters justify="space-between" align="center">
      <span>
        <v-card-title
          class="pt-5 tertiary--text"
          v-if="type === 'CUSTOM_VEHICLES'"
        >
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          My Vehicles
        </v-card-title>
        <v-card-title
          v-if="type === 'CONNECTED_VEHICLES'"
          class="pt-5 tertiary--text"
        >
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          Connected Vehicles
        </v-card-title>
        <v-card-title
          v-if="type === 'GENERIC_VEHICLES'"
          class="pt-5 tertiary--text"
        >
          <v-btn icon @click="back">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          Generic EVs
        </v-card-title>
      </span>
      <v-btn icon>
        <v-icon @click="close">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-text-field
      v-model="searchQuery"
      prepend-inner-icon="mdi-magnify"
      rounded
      filled
      clearable
      hide-details
      dense
      class="mx-5 mb-3"
    />
    <v-card-text v-if="type === 'CUSTOM_VEHICLES'">
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in customVehicles"
          :vehicle="vehicle"
          :key="`vehicle-card-${index}`"
          @select="select"
        />
        <CardLikeButton />
      </v-row>
    </v-card-text>

    <v-card-text v-if="type === 'CONNECTED_VEHICLES'">
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in connectedVehicles"
          :vehicle="vehicle"
          :key="`vehicle-card-${index}`"
          @select="select"
        />
      </v-row>
    </v-card-text>

    <v-card-text v-if="type === 'GENERIC_VEHICLES'">
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in genericVehicles"
          :vehicle="vehicle"
          :key="`generic-vehicle-card-${index}`"
          @select="select"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import VehicleGalleryCard from "./VehicleGalleryCard.vue";
import CardLikeButton from "./CardLikeButton.vue";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  VehicleGalleryType,
} from "@/logic/store/store_types";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";

/** `Vue component:` renders a full list of selectable vehicles to be
 * displayed in the `MainDialog` and `MobileDialog` components*/
export default Vue.extend({
  name: "VehicleGalleryContent",
  data() {
    return {
      panel: [0, 1],
      searchQuery: "",
    };
  },
  props: {
    type: {
      required: true,
      type: String as PropType<VehicleGalleryType>,
    },
  },
  computed: {
    connectedVehicles() {
      const unfilteredData: Vehicle[] =
        this.$store.getters[GettersTypes.connectedVehicles];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return unfilteredData.filter((vehicle) => {
          if (vehicle.name && vehicle.name.toLowerCase().includes(query))
            return vehicle;
          if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query))
            return vehicle;
          if (
            vehicle.licensePlate &&
            vehicle.licensePlate.toLowerCase().includes(query)
          )
            return vehicle;
          if (vehicle.evModel) {
            if (vehicle.evModel.name.toLowerCase().includes(query))
              return vehicle;
          }
        });
      }
      return unfilteredData;
    },
    genericVehicles() {
      const unfilteredData: Vehicle[] =
        this.$store.getters[GettersTypes.genericVehicles];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return unfilteredData.filter((vehicle) => {
          if (vehicle.name && vehicle.name.toLowerCase().includes(query))
            return vehicle;
          if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query))
            return vehicle;
          if (
            vehicle.licensePlate &&
            vehicle.licensePlate.toLowerCase().includes(query)
          )
            return vehicle;
          if (vehicle.evModel) {
            if (vehicle.evModel.name.toLowerCase().includes(query))
              return vehicle;
          }
        });
      }
      return unfilteredData;
    },
    customVehicles() {
      const unfilteredData: Vehicle[] =
        this.$store.getters[GettersTypes.customVehicles];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return unfilteredData.filter((vehicle) => {
          if (vehicle.name && vehicle.name.toLowerCase().includes(query))
            return vehicle;
          if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query))
            return vehicle;
          if (
            vehicle.licensePlate &&
            vehicle.licensePlate.toLowerCase().includes(query)
          )
            return vehicle;
          if (vehicle.evModel) {
            if (vehicle.evModel.name.toLowerCase().includes(query))
              return vehicle;
          }
        });
      }
      return unfilteredData;
    },
  },
  components: { VehicleGalleryCard, CardLikeButton },
  mounted() {
    this.$nextTick(() => {
      if (!this.connectedVehicles.length) {
        this.panel = [1];
      }
    });
  },
  methods: {
    /** closes all modals and returns to the normal vue of the app. */
    close() {
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
    },
    /** swaps modal content back to the target `Vehicles` vehicle details page */
    back() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY
      );
    },
    select(vehicle: Vehicle) {
      this.$store.dispatch(ActionTypes.selectVehicle, vehicle);
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
    },
  },
});
</script>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('LHotline',{attrs:{"latLngs":_vm.latLngArray,"min":0,"max":1,"palette":{
    0.2: '#F44336',
    0.3: '#9C27B0',
    0.4: '#0D47A1',
    0.9: '#2196F3',
  },"outline-color":"white","weight":5,"outline-width":1,"interactive":false},on:{"click":_vm.handleClick}},[_c('l-popup',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('ElevatedBlockBtn',{on:{"click":_vm.displayLatLng}},[_vm._v(" Find chargers near here ")])],1)],1)],1),_c('AddChargingStopContent',{attrs:{"activator":_vm.showDialog,"centerLatLng":_vm.popupLatLng
        ? { latitude: _vm.popupLatLng[0], longitude: _vm.popupLatLng[1] }
        : undefined},on:{"close":function($event){_vm.showDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
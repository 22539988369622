<template>
  <div class="pwt-custom-overlay">
    <v-card
      class="px-4 pb-3 rounded-0 d-flex flex-column align-items-start justify-start"
      light
      :max-width="$vuetify.breakpoint.xsOnly ? '' : '800px'"
      :min-width="$vuetify.breakpoint.xsOnly ? '' : '350px'"
      :min-height="$vuetify.breakpoint.xsOnly ? '' : '100px'"
      :width="$vuetify.breakpoint.xsOnly ? '100vw' : '40%'"
      height="100vh"
      @keydown="keyDown($event)"
    >
      <template v-if="checkingToken">
        <v-card-text
          v-if="$vuetify.breakpoint.xsOnly"
          class="d-flex flex-column align-items-center justify-center"
        >
          <span style="width: 100%" class="text-h5 text-center">
            Cheeking authentication
          </span>
          <div style="width: 100%" class="d-flex justify-center">
            <LoadingCard />
          </div>
        </v-card-text>
        <v-card-text
          style="height: 100%; min-height: 100px; position: relative"
          class="d-flex justify-center align-center px-8 pt-5"
          v-else
        >
          <v-progress-circular indeterminate color="primary" class="mr-2" />
          <span class="text-h5">Checking authentication</span>
          <PulseLoader />
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title
          class="text-h3"
          style="font-family: Public Sans !important"
        >
          Optimiser
        </v-card-title>
        <v-card-text class="text-body-1 font-weight-medium" style="">
          Power Trip's complete EV management suite
        </v-card-text>
        <template v-if="status === 'FAILED' || status === undefined">
          <v-card flat v-if="step === 'email'">
            <v-card-text class="grey--text pb-1">
              Your email address
            </v-card-text>
            <v-text-field
              v-model="email"
              placeholder="Login with your email address.."
              class="ml-5 mr-13 mb-5 mt-0"
              hide-details
              type="email"
              autocomplete="username"
            ></v-text-field>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <ElevatedBtn @click="() => (step = 'password')">
                Continue
              </ElevatedBtn>
            </v-row>
          </v-card>
          <v-card flat v-if="step === 'password'">
            <v-card-text class="grey--text pb-1"> Password </v-card-text>
            <v-text-field
              v-model="password"
              :type="passwordType"
              placeholder="Password.."
              append-outer-icon="mdi-eye"
              @click:append-outer="togglePassword"
              class="mx-5 mb-5 mt-0"
              hide-details
              autocomplete="current-password"
            ></v-text-field>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <TextBtn @click="() => (step = 'email')"> Go back </TextBtn>
              <ElevatedBtn @click="login"> Login </ElevatedBtn>
            </v-row>
          </v-card>
        </template>
        <v-card-text class="d-flex red--text" v-if="status === 'FAILED'"
          ><v-icon color="red" class="mr-1">mdi-alert-circle</v-icon> Whoops!
          Looks like your login details are not correct. Please check your
          details and try again.</v-card-text
        >
        <v-card-text class="d-flex" v-if="status === 'SUCCESS'">
          <v-icon color="green" class="mr-1">mdi-check-circle</v-icon>
          Successfully authenticated.
        </v-card-text>
        <template v-if="status === 'PROCESSING'">
          <v-card-text
            v-if="$vuetify.breakpoint.xsOnly"
            class="d-flex align-items-center justify-center"
          >
            <LoadingCard />
          </v-card-text>
          <v-card-text v-else class="d-flex align-items-center">
            <v-progress-circular
              indeterminate
              color="primary"
              class="mr-1"
              size="22"
            />
            <span class="d-flex align-center">
              <span> Processing </span>
              <PulseLoader />
            </span>
          </v-card-text>
        </template>
        <v-btn
          :href="`https://sandbox.gameplan.powertrip.earth/auth/login/sso?prompt=login&redirect=${currentUrl}`"
          color="#f3f3f3"
          class="text-none rounded-pill"
          style="position: absolute; bottom: 20px; right: 20px"
        >
          Login with SSO
        </v-btn>
      </template>
    </v-card>
  </div>
</template>
<script lang="ts">
import PulseLoader from "../../ui-elements/loaders/PulseLoader.vue";
import Vue from "vue";
import { mapState } from "vuex";
import LoadingCard from "../../ui-elements/loaders/LoadingCard.vue";
import { State } from "@/logic/store/store_types";
import { ActionTypes } from "@/logic/store/store_types";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
import TextBtn from "../../ui-elements/buttons/TextBtn.vue";

export default Vue.extend({
  name: "LoginOverlay",
  computed: {
    ...mapState({
      display(state: unknown) {
        const display = (state as State).showLogin;
        if (!display) {
          // eslint-disable-next-line
          // @ts-ignore
          this.email = "";
          // eslint-disable-next-line
          // @ts-ignore
          this.password = "";
        }
        return display;
      },
      status: (state: unknown) => (state as State).authStatus,
      checkingToken: (state: unknown) => (state as State).checkingToken,
    }),
    currentUrl() {
      return window.location.origin + window.location.pathname;
    },
  },
  data() {
    return {
      email: "",
      password: "",
      passwordType: "password",
      step: "email" as "email" | "password",
    };
  },
  methods: {
    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    login() {
      this.$store.dispatch(ActionTypes.login, {
        email: this.email,
        password: this.password,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Enter") {
        if (this.step === "email") {
          this.step = "password";
        } else if (this.step === "password") {
          this.login();
        }
      }
    },
  },
  components: {
    PulseLoader,
    LoadingCard,
    ElevatedBtn,
    TextBtn,
  },
});
</script>
<style scoped>
.pwt-custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1400;
  display: flex;
  backdrop-filter: blur(2px);
}
</style>

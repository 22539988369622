<template>
  <!-- planning animation page -->
  <InfoPanelCardWrapper v-if="planning">
    <DescriptiveLoader message="Almost there!" />
  </InfoPanelCardWrapper>
  <!-- planning form -->
  <InfoPanelCardWrapper v-else style="padding-bottom: 100px !important">
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-md-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <!-- locations list section -->
    <LocationsListRow
      :addressData="originAddressData"
      id="origin"
      @update="updateAddress"
      type="origin"
      :loading="addressLoading"
      :readonly="true"
    />
    <LocationsListRow
      v-for="stop in waypoints"
      :key="stop.local_id"
      :addressData="{
        address: stop.address,
        ...stop.coordinates.asCapitalizedObj,
      }"
      :id="stop.local_id"
      @update="updateAddress"
      type="stop"
      :loading="addressLoading"
      :errorMsg="addressErrors"
      readonly
      appendIcon="mdi-close"
      @append="handleRemoveWaypoint(stop.local_id)"
      @click="handleShowEditWaypoint(stop)"
    />
    <LocationsListRow
      :addressData="destinationAddressData"
      id="destination"
      @update="updateAddress"
      type="destination"
      :loading="addressLoading"
      :readonly="true"
    />
    <v-card-title class="px-0 d-flex align-start flex-column flex-md-row">
      Add Stops
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <OutlinedBtn
        v-if="$vuetify.breakpoint.mdAndUp"
        @click="addSavedLocationsDialogActivator = true"
      >
        Add stops from saved locations
      </OutlinedBtn>
      <OutlinedBlockBtn
        v-else
        @click="addSavedLocationsDialogActivator = true"
        class="mt-2"
      >
        Add stops from saved locations
      </OutlinedBlockBtn>
    </v-card-title>
    <WaypointPlanningCard
      @add-stop="handleAddWaypoint"
      :remainingLoad="remainingLoad"
      :originCoordinates="
        originAddressData
          ? {
              lat: originAddressData.coordinates.Latitude,
              lon: originAddressData.coordinates.Longitude,
            }
          : { lat: 0, lon: 0 }
      "
      :destinationCoordinates="
        destinationAddressData
          ? {
              lat: destinationAddressData.coordinates.Latitude,
              lon: destinationAddressData.coordinates.Longitude,
            }
          : { lat: 0, lon: 0 }
      "
    />
    <!-- Actions section -->
    <div
      class="px-5"
      :class="classes"
      style="
        position: fixed;
        top: calc(100dvh - 50px);
        left: 0;
        width: 40%;
        max-width: 600px;
      "
      :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;' : ''"
    >
      <v-row no-gutters>
        <v-col cols="5" cols-md="4" class="pr-1">
          <div class="background rounded-lg">
            <ElevatedBlockBtn
              color="#dadada"
              darkText
              @click="handleOptimiseStops"
              :disabled="waypoints.length < 2 ? true : preventOptimising"
            >
              Optimise stops
            </ElevatedBlockBtn>
          </div>
        </v-col>
        <v-col cols="7" cols-md="8" class="pl-1">
          <div class="background rounded-lg">
            <ElevatedBlockBtn
              @click="handleCreateTrip"
              class="mb-3"
              :disabled="preventPlaning || addressLoading"
              :loading="addressLoading"
            >
              Select a vehicle
            </ElevatedBlockBtn>
          </div>
        </v-col>
      </v-row>
    </div>
    <GenericDialog
      :activator="showStopEditDialog"
      @close="showStopEditDialog = false"
    >
      <WaypointEditForm
        :remainingLoad="remainingLoad"
        :initialValue="editingStop"
        @update="handleUpdateWaypoint"
      />
    </GenericDialog>
    <AddSavedLocations
      :activator="addSavedLocationsDialogActivator"
      @close="addSavedLocationsDialogActivator = false"
      @addStops="handleAddMultipleWaypoints"
    />
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import ElevatedBlockBtn from "../components/ui-elements/buttons/ElevatedBlockBtn.vue";
import InfoPanelCardWrapper from "../components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import queryValueToString from "@/logic/utils/queryValueToString";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import LocationsListRow from "../components/trips/planning/LocationsListRow.vue";
import { AddressAutocompleteInputUpdateObj } from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import WaypointPlanningCard from "../components/trips/planning/WaypointPlanningCard.vue";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import Trip from "@/logic/classes/trip_classes/trip";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { Dictionary, RawLocation } from "vue-router/types/router";
import TripsHomeBtn from "../components/ui-elements/buttons/TripsHomeBtn.vue";
import DescriptiveLoader from "../components/ui-elements/loaders/DescriptiveLoader.vue";
import { quickTripCheck } from "@/logic/api/calls/valhalla_calls";
import { QuickTripCheckReturn } from "@/logic/types/valhalla_types";
import { mapGetters, mapState } from "vuex";
import GenericDialog from "../components/dialog-wrappers/GenericDialog.vue";
import WaypointEditForm from "../components/trips/planning/WaypointEditForm.vue";
import OutlinedBtn from "../components/ui-elements/buttons/OutlinedBtn.vue";
import AddSavedLocations from "../components/trips/planning/AddSavedLocations.vue";
import OutlinedBlockBtn from "../components/ui-elements/buttons/OutlinedBlockBtn.vue";

export default Vue.extend({
  name: "PlanningAddStopsView",
  components: {
    ElevatedBlockBtn,
    InfoPanelCardWrapper,
    LocationsListRow,
    WaypointPlanningCard,
    TripsHomeBtn,
    DescriptiveLoader,
    GenericDialog,
    WaypointEditForm,
    OutlinedBtn,
    AddSavedLocations,
    OutlinedBlockBtn,
  },
  data() {
    return {
      // address list section data
      originAddressData: undefined as processedAddressObj | undefined,
      destinationAddressData: undefined as processedAddressObj | undefined,
      waypoints: [] as TripLocation[],
      remainingLoad: 0,
      // control
      planning: false,
      addressLoading: false,
      addressErrors: null as string | null,
      preventPlaning: false,
      preventOptimising: false,
      showStopEditDialog: false,
      editingStop: undefined as TripLocation | undefined,
      addSavedLocationsDialogActivator: false,
    };
  },
  computed: {
    items(): { text: string; to: RawLocation }[] {
      const useSmall = this.$vuetify.breakpoint.smAndDown;

      if ((this.$store.state as State).selectedTrip)
        return [
          {
            text: useSmall ? "Destination" : "Add Destination",
            to: { name: RouteNames.tripAddDestination },
          },
          {
            text: useSmall ? "Origin" : "Add Origin",
            to: { name: RouteNames.tripAddOrigin },
          },
          {
            text: useSmall ? "Details" : "Add Details",
            to: { name: RouteNames.tripAddDetails },
          },
          {
            text: useSmall ? "Stops" : "Add Stops",
            to: { name: RouteNames.tripAddStops },
          },
          {
            text: useSmall ? "Vehicle" : "Select Vehicle",
            to: { name: RouteNames.tripSelectVehicle },
          },
          {
            text: "Itinerary",
            to: { name: RouteNames.tripItinerary },
          },
        ];
      return [
        {
          text: useSmall ? "Destination" : "Add Destination",
          to: { name: RouteNames.tripAddDestination },
        },
        {
          text: useSmall ? "Origin" : "Add Origin",
          to: { name: RouteNames.tripAddOrigin },
        },
        {
          text: useSmall ? "Details" : "Add Details",
          to: { name: RouteNames.tripAddDetails },
        },
        {
          text: useSmall ? "Stops" : "Add Stops",
          to: { name: RouteNames.tripAddStops },
        },
      ];
    },
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
      fetching: GettersTypes.fetching,
    }),
    ...mapState({
      animation: (state: unknown) => {
        return (state as State).infoPanelAnimation;
      },
    }),
    classes() {
      if (this.animation == "slide-left")
        return "pwt-info-panel-right-to-left-animation";
      if (this.animation == "slide-right")
        return "pwt-info-panel-left-to-right-animation";
      return "";
    },
  },
  methods: {
    log(message: string) {
      console.log(message);
    },
    /**
     * Routes the user to the specified route, with the given query.
     * @param routeName The name of the route to route to.
     * @param query The query object to pass to the route. If not provided, no query will be sent.
     */
    pushRoute(
      routeName: RouteNames,
      query:
        | Dictionary<string | (string | null)[] | null | undefined>
        | undefined = undefined
    ) {
      this.$router.push({ name: routeName, query: query });
    },
    /**
     * Routes the user to the Itinerary page.
     * This method is simply a wrapper around pushRoute() to make it easier
     * to read in the template.
     */
    toItinerary() {
      this.pushRoute(RouteNames.tripItinerary);
    },
    /**
     * Updates the URL query string with the current state of the origin, destination
     * and waypoints. It will remove any old stops and re-add new stops. If nothing
     * has changed, it will not update the query string.
     */
    updateRouteQuery() {
      const newQuery = {
        ...this.$route.query,
      };

      if (this.destinationAddressData) {
        newQuery.destAddress = encodeURI(this.destinationAddressData.address);
        newQuery.destLat =
          this.destinationAddressData.coordinates.Latitude.toString();
        newQuery.destLon =
          this.destinationAddressData.coordinates.Longitude.toString();
        if (this.destinationAddressData.name) {
          newQuery.destName = this.destinationAddressData.name;
        } else {
          delete newQuery.destName;
        }
      } else {
        delete newQuery.destAddress;
        delete newQuery.destLat;
        delete newQuery.destLon;
        delete newQuery.destName;
      }

      if (this.originAddressData) {
        newQuery.origAddress = encodeURI(this.originAddressData.address);
        newQuery.origLat =
          this.originAddressData.coordinates.Latitude.toString();
        newQuery.origLon =
          this.originAddressData.coordinates.Longitude.toString();
        if (this.originAddressData.name) {
          newQuery.origName = this.originAddressData.name;
        } else {
          delete newQuery.origName;
        }
      } else {
        delete newQuery.origAddress;
        delete newQuery.origLat;
        delete newQuery.origLon;
        delete newQuery.origName;
      }

      // rebuild stops

      Object.keys(newQuery)
        .filter((key) => key.startsWith("stop"))
        .forEach((key) => {
          delete newQuery[key];
        }); // remove old stops

      this.waypoints.forEach((stop, index) => {
        newQuery[`stop${index + 1}Address`] = encodeURI(stop.address);
        newQuery[`stop${index + 1}Lat`] = stop.coordinates.latitude.toString();
        newQuery[`stop${index + 1}Lon`] = stop.coordinates.longitude.toString();
        if (stop.name) newQuery[`stop${index + 1}Name`] = encodeURI(stop.name);
        if (stop.stay) newQuery[`stop${index + 1}Stay`] = stop.stay.toString();
        if (stop.stateOfChargeAfterCharging)
          newQuery[`stop${index + 1}LeavingSoC`] =
            stop.stateOfChargeAfterCharging.toString();
        if (stop.weightChange)
          newQuery[`stop${index + 1}Weight`] = stop.weightChange.toString();
        if (stop.nonDrivingEnergyUsed)
          newQuery[`stop${index + 1}UsedEnergy`] =
            stop.nonDrivingEnergyUsed.toString();
      }); // add new stops

      // check if nothing changed
      if (
        Object.keys(newQuery).every(
          (key) => newQuery[key] === this.$route.query[key]
        ) &&
        Object.keys(this.$route.query).every(
          (key) => newQuery[key] === this.$route.query[key]
        )
      )
        return; // nothing to update

      // update query
      this.$router.replace({
        query: newQuery,
      });
    },
    /**
     * Handles address updates from the AddressAutocompleteInput components.
     * Used for updating the origin, destination, and waypoints in the trip.
     * If the address is an origin or destination, it will update the
     * corresponding class property and call updateRouteQuery().
     * If the address is a waypoint, it will update the corresponding waypoint
     * in the waypoints array and call updateRouteQuery().
     * @param {AddressAutocompleteInputUpdateObj} address - The address data
     * from the AddressAutocompleteInput component.
     */
    async updateAddress(address: AddressAutocompleteInputUpdateObj) {
      if (address.id === "origin") {
        this.originAddressData = address.addressData;
        this.updateRouteQuery();
      } else if (address.id === "destination") {
        this.destinationAddressData = address.addressData;
        this.updateRouteQuery();
      } else {
        // ASSUMES: is a waypoint
        if (address.addressData) {
          // update waypoint
          const newWaypoint = new TripLocation({
            local_id: address.id,
            name: address.addressData.name,
            address: address.addressData.address,
            coordinates: new Coordinate({
              latitude: address.addressData.coordinates.Latitude,
              longitude: address.addressData.coordinates.Longitude,
            }),
          });
          const copyWaypoints = [...this.waypoints];
          const index = copyWaypoints.findIndex(
            (waypoint) => waypoint.local_id === address.id
          );
          if (index === -1) return;
          copyWaypoints[index] = newWaypoint;
          this.waypoints = copyWaypoints;
        } else {
          this.removeWaypoint(address.id);
        }
        this.updateRouteQuery();
        await this.quickPlan();
        this.preventOptimising = false;
      }
    },
    removeWaypoint(id: string) {
      // remove waypoint
      const copyWaypoints = [...this.waypoints];
      const index = copyWaypoints.findIndex(
        (waypoint) => waypoint.local_id === id
      );
      if (index === -1) return;
      copyWaypoints.splice(index, 1);
      this.waypoints = copyWaypoints;
    },
    handleRemoveWaypoint(id: string) {
      this.removeWaypoint(id);
      this.updateRouteQuery();
      this.quickPlan();
      this.preventOptimising = false;
    },
    handleShowEditWaypoint(stop: TripLocation) {
      this.editingStop = stop;
      this.showStopEditDialog = true;
    },
    handleUpdateWaypoint(stop: TripLocation) {
      this.editingStop = undefined;
      this.showStopEditDialog = false;
      const index = this.waypoints.findIndex(
        (w) => w.local_id === stop.local_id
      );
      if (index === -1) return;
      this.waypoints[index] = stop;
      this.trip.updateExistingLocation(stop);
      this.updateRouteQuery();
      this.quickPlan();
      this.preventOptimising = false;
    },
    /**
     * Handles adding a new waypoint to the list of waypoints and updating the
     * route query in the URL.
     *
     * @param {TripLocation} val - The waypoint to add
     */
    async handleAddWaypoint(val: TripLocation) {
      this.preventOptimising = false;
      this.waypoints.push(val);
      this.updateRouteQuery();
      await this.quickPlan();
    },
    async handleAddMultipleWaypoints(val: TripLocation[]) {
      this.preventOptimising = false;
      this.waypoints.push(...val);
      this.updateRouteQuery();
      await this.quickPlan();
    },
    /**
     * Generates a new itinerary based on the new polyline fetched from valhalla
     * based on the current waypoints.
     *
     * After the trip plan has been generated, it will update the store with the
     * new trip data and set the selected trip to the just-planned trip.
     *
     * @return {Promise<void>}
     */
    async quickPlan() {
      if (!this.originAddressData) return;
      if (!this.destinationAddressData) return;

      this.planning = true;
      const trip: Trip | undefined = this.trip;
      if (!trip) {
        this.planning = false;
        return;
      }
      // prep locations
      const locations: TripLocation[] = [];
      // add origin
      locations.push(
        new TripLocation({
          address: this.originAddressData.address,
          coordinates: new Coordinate({
            latitude: this.originAddressData.coordinates.Latitude,
            longitude: this.originAddressData.coordinates.Longitude,
          }),
          name: this.originAddressData.name,
        })
      );
      // add stops
      locations.push(...this.waypoints);
      // add destination
      locations.push(
        new TripLocation({
          address: this.destinationAddressData.address,
          coordinates: new Coordinate({
            latitude: this.destinationAddressData.coordinates.Latitude,
            longitude: this.destinationAddressData.coordinates.Longitude,
          }),
          name: this.destinationAddressData.name,
        })
      );
      trip.locations = locations;
      await trip.quickPlan(true);
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      this.$store.commit(MutationTypes.setSelectedTrip, trip.local_id);
      this.planning = false;
    },
    /**
     * Creates a new trip based on the currently selected origin, waypoints
     * and destination. The newly created trip is then selected and the
     * user is redirected to the vehicle selection page.
     *
     * Note that this method will only work if the origin and destination
     * have been set, and if there are no other trips currently in progress.
     * If either of these conditions is not met, the method will return early
     * and not do anything.
     *
     * @returns {Promise<void>}
     */
    async handleCreateTrip() {
      // guard clauses
      if (!this.originAddressData) {
        // TODO: show error
        return;
      }

      if (!this.destinationAddressData) {
        // TODO: show error
        return;
      }

      // prep locations
      const locations: TripLocation[] = [];
      // add origin
      locations.push(
        new TripLocation({
          address: this.originAddressData.address,
          coordinates: new Coordinate({
            latitude: this.originAddressData.coordinates.Latitude,
            longitude: this.originAddressData.coordinates.Longitude,
          }),
          name: this.originAddressData.name,
        })
      );
      // add stops
      locations.push(...this.waypoints);
      // add destination
      locations.push(
        new TripLocation({
          address: this.destinationAddressData.address,
          coordinates: new Coordinate({
            latitude: this.destinationAddressData.coordinates.Latitude,
            longitude: this.destinationAddressData.coordinates.Longitude,
          }),
          name: this.destinationAddressData.name,
        })
      );
      // create trip
      const trip = new Trip({
        locations: locations,
        roundTripFlag: false,
      });
      trip.SOCAct =
        queryValueToNumber(this.$route.query.SOCAct) ??
        (this.$store.state as State).SOCAct;
      trip.SOCEnd =
        queryValueToNumber(this.$route.query.SOCEnd) ??
        (this.$store.state as State).SOCEnd;
      trip.SOCMin =
        queryValueToNumber(this.$route.query.SOCMin) ??
        (this.$store.state as State).SOCMin;
      trip.SOCMax =
        queryValueToNumber(this.$route.query.SOCMax) ??
        (this.$store.state as State).SOCMax;
      trip.SpeedAdjustment = queryValueToNumber(this.$route.query.speedAdj);
      trip.startingLoad =
        queryValueToNumber(this.$route.query.extraWeight) ?? 0;
      trip.passengers = queryValueToNumber(this.$route.query.passengers) ?? 0;
      trip.vehicle = this.$store.getters[GettersTypes.selectedVehicleData] as
        | Vehicle
        | undefined;
      // plan trip
      this.planning = true;
      await trip.currentTripToComparison();
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      this.$store.commit(MutationTypes.setSelectedTrip, trip.local_id);
      this.$router.push({
        name: RouteNames.tripSelectVehicle,
        query: this.$route.query,
      });
      this.planning = false;
    },
    async buildTripFromQuery() {
      this.planning = true; // trigger planning animation

      // get query values
      const originAddress = queryValueToString(this.$route.query.origAddress);
      const originLat = queryValueToNumber(this.$route.query.origLat);
      const originLon = queryValueToNumber(this.$route.query.origLon);
      const originName = queryValueToString(this.$route.query.origName);

      const destAddress = queryValueToString(this.$route.query.destAddress);
      const destLat = queryValueToNumber(this.$route.query.destLat);
      const destLon = queryValueToNumber(this.$route.query.destLon);
      const destName = queryValueToString(this.$route.query.destName);

      let waypoints: TripLocation[] = [];
      const stopKeys = Object.keys(this.$route.query).filter((key) =>
        key.startsWith("stop")
      );
      let groupedKeys = [];
      for (let i = 0; i < stopKeys.length; i++) {
        const key = stopKeys[i];
        const stopNumber = parseIntOrFloat(key[4]); // ASSUMES pattern of `stop[stop index + 1][stop property key]` is still being used
        if (!stopNumber) continue;
        if (!groupedKeys[stopNumber - 1]) groupedKeys[stopNumber - 1] = [key];
        else groupedKeys[stopNumber - 1].push(key);
      }
      for (let index = 0; index < groupedKeys.length; index++) {
        const group = groupedKeys[index];
        const newWaypoint = new TripLocation();
        for (let i = 0; i < group.length; i++) {
          const key = group[i];
          if (key === `stop${index + 1}Address`) {
            const parsedAddress = queryValueToString(this.$route.query[key]);
            if (parsedAddress) newWaypoint.address = parsedAddress;
          }
          if (key === `stop${index + 1}Lat`) {
            const parsedLat = queryValueToNumber(this.$route.query[key]);
            if (parsedLat) newWaypoint.coordinates.latitude = parsedLat;
          }
          if (key === `stop${index + 1}Lon`) {
            const parsedLon = queryValueToNumber(this.$route.query[key]);
            if (parsedLon) newWaypoint.coordinates.longitude = parsedLon;
          }
          if (key === `stop${index + 1}Name`) {
            const parsedName = queryValueToString(this.$route.query[key]);
            if (parsedName) newWaypoint.name = parsedName;
          }
          if (key === `stop${index + 1}Stay`) {
            const parsedStay = queryValueToNumber(this.$route.query[key]);
            if (parsedStay) newWaypoint.stay = parsedStay;
          }
          if (key === `stop${index + 1}LeavingSoC`) {
            const parsedSoC = queryValueToNumber(this.$route.query[key]);
            if (parsedSoC) newWaypoint.stateOfChargeAfterCharging = parsedSoC;
          }
          if (key === `stop${index + 1}Weight`) {
            const parsedWeight = queryValueToNumber(this.$route.query[key]);
            if (parsedWeight) newWaypoint.weightChange = parsedWeight;
          }
          if (key === `stop${index + 1}UsedEnergy`) {
            const parsedEnergy = queryValueToNumber(this.$route.query[key]);
            if (parsedEnergy) newWaypoint.nonDrivingEnergyUsed = parsedEnergy;
          }
        }
        waypoints.push(newWaypoint);
      }
      // guard clauses
      if (!originAddress || !originLat || !originLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      if (!destAddress || !destLat || !destLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      // prep locations
      const locations: TripLocation[] = [];
      // add origin
      locations.push(
        new TripLocation({
          address: originAddress,
          coordinates: new Coordinate({
            latitude: originLat,
            longitude: originLon,
          }),
          name: originName,
        })
      );
      // add stops

      locations.push(...waypoints);
      // add destination
      locations.push(
        new TripLocation({
          address: destAddress,
          coordinates: new Coordinate({
            latitude: destLat,
            longitude: destLon,
          }),
          name: destName,
        })
      );
      // create trip
      const trip = new Trip({
        locations: locations,
        roundTripFlag: false,
      });
      trip.SOCAct =
        queryValueToNumber(this.$route.query.SOCAct) ??
        (this.$store.state as State).SOCAct;
      trip.SOCEnd =
        queryValueToNumber(this.$route.query.SOCEnd) ??
        (this.$store.state as State).SOCEnd;
      trip.SOCMin =
        queryValueToNumber(this.$route.query.SOCMin) ??
        (this.$store.state as State).SOCMin;
      trip.SOCMax =
        queryValueToNumber(this.$route.query.SOCMax) ??
        (this.$store.state as State).SOCMax;
      trip.SpeedAdjustment = queryValueToNumber(this.$route.query.speedAdj);
      trip.startingLoad =
        queryValueToNumber(this.$route.query.extraWeight) ?? 0;
      trip.passengers = queryValueToNumber(this.$route.query.passengers) ?? 0;
      trip.vehicle = this.$store.getters[GettersTypes.selectedVehicleData] as
        | Vehicle
        | undefined;
      // plan trip
      await trip.quickPlan();
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      this.$store.commit(MutationTypes.setSelectedTrip, trip.local_id);
      this.planning = false;
    },
    async handleOptimiseStops() {
      this.planning = true;
      const trip = this.trip as Trip | undefined;
      if (!trip) {
        this.planning = false;
        return;
      }
      if (this.waypoints.length < 2) {
        this.planning = false;
        return;
      }
      await trip.optimiseWaypoints();
      const newWaypoints = [...trip.locations];
      // remove first waypoint because it's the origin
      newWaypoints.shift();
      // remove last waypoint because it's the destination if not a round trip
      if (!trip.roundTripFlag) newWaypoints.pop();
      // update local state
      this.waypoints = newWaypoints;
      // rebuild query
      this.updateRouteQuery();
      // update store
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      this.preventOptimising = true;
      this.planning = false;
    },
  },
  mounted() {
    if (this.$route.query.destAddress) {
      const address = queryValueToString(this.$route.query.destAddress);
      const lat = queryValueToNumber(this.$route.query.destLat);
      const lon = queryValueToNumber(this.$route.query.destLon);
      const name = queryValueToString(this.$route.query.destName);
      if (address && lat && lon) {
        this.destinationAddressData = {
          address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name,
        };
      }
    }

    if (this.$route.query.origAddress) {
      const address = queryValueToString(this.$route.query.origAddress);
      const lat = queryValueToNumber(this.$route.query.origLat);
      const lon = queryValueToNumber(this.$route.query.origLon);
      const name = queryValueToString(this.$route.query.origName);
      if (address && lat && lon) {
        this.originAddressData = {
          address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name,
        };
      }
    }

    let remainingLoad = queryValueToNumber(this.$route.query.extraWeight) ?? 0;

    const stopKeys = Object.keys(this.$route.query).filter((key) =>
      key.startsWith("stop")
    );
    let groupedKeys = [];
    for (let i = 0; i < stopKeys.length; i++) {
      const key = stopKeys[i];
      const stopNumber = parseIntOrFloat(key[4]); // ASSUMES pattern of `stop[stop index + 1][stop property key]` is still being used
      if (!stopNumber) continue;
      if (!groupedKeys[stopNumber - 1]) groupedKeys[stopNumber - 1] = [key];
      else groupedKeys[stopNumber - 1].push(key);
    }
    for (let index = 0; index < groupedKeys.length; index++) {
      const group = groupedKeys[index];
      const newWaypoint = new TripLocation();
      for (let i = 0; i < group.length; i++) {
        const key = group[i];
        if (key === `stop${index + 1}Address`) {
          const parsedAddress = queryValueToString(this.$route.query[key]);
          if (parsedAddress) newWaypoint.address = parsedAddress;
        }
        if (key === `stop${index + 1}Lat`) {
          const parsedLat = queryValueToNumber(this.$route.query[key]);
          if (parsedLat) newWaypoint.coordinates.latitude = parsedLat;
        }
        if (key === `stop${index + 1}Lon`) {
          const parsedLon = queryValueToNumber(this.$route.query[key]);
          if (parsedLon) newWaypoint.coordinates.longitude = parsedLon;
        }
        if (key === `stop${index + 1}Name`) {
          const parsedName = queryValueToString(this.$route.query[key]);
          if (parsedName) newWaypoint.name = parsedName;
        }
        if (key === `stop${index + 1}Stay`) {
          const parsedStay = queryValueToNumber(this.$route.query[key]);
          if (parsedStay) newWaypoint.stay = parsedStay;
        }
        if (key === `stop${index + 1}LeavingSoC`) {
          const parsedSoC = queryValueToNumber(this.$route.query[key]);
          if (parsedSoC) newWaypoint.stateOfChargeAfterCharging = parsedSoC;
        }
        if (key === `stop${index + 1}Weight`) {
          const parsedWeight = queryValueToNumber(this.$route.query[key]);
          if (parsedWeight) {
            remainingLoad += parsedWeight;
            newWaypoint.weightChange = parsedWeight;
          }
        }
        if (key === `stop${index + 1}UsedEnergy`) {
          const parsedEnergy = queryValueToNumber(this.$route.query[key]);
          if (parsedEnergy) newWaypoint.nonDrivingEnergyUsed = parsedEnergy;
        }
      }
      this.waypoints.push(newWaypoint);
    }

    this.remainingLoad = remainingLoad;

    this.$nextTick(async () => {
      // ASSUMES: if still fetching base optimiser data watch will be triggered once finished.
      if (!this.fetching) {
        if (this.trip) {
          await this.updateRouteQuery();
          this.planning = false;
        }
        if (!this.trip) {
          await this.buildTripFromQuery();
        }
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripAddStops ||
        to.name === RouteNames.tripAddDetails ||
        to.name === RouteNames.tripAddOrigin ||
        to.name === RouteNames.tripAddDestination ||
        to.name === RouteNames.tripSelectVehicle ||
        to.name === RouteNames.tripStats) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
  watch: {
    async waypoints(val: TripLocation[]) {
      if (val.length === 0) {
        // ASSUMEs back to only origin and destination.
        // clear address errors as routable.
        this.addressErrors = null;
        this.preventPlaning = false;
        return;
      }
      if (val.length && val.every((location) => location.coordinates.isValid)) {
        this.addressLoading = true;
        const quickCheckRes = await quickTripCheck([
          {
            lat: this.originAddressData?.coordinates.Latitude ?? 0,
            lon: this.originAddressData?.coordinates.Longitude ?? 0,
          },
          ...val.map((location) => ({
            lat: location.coordinates.latitude,
            lon: location.coordinates.longitude,
          })),
          {
            lat: this.destinationAddressData?.coordinates.Latitude ?? 0,
            lon: this.destinationAddressData?.coordinates.Longitude ?? 0,
          },
        ]);
        if (quickCheckRes === QuickTripCheckReturn.routable) {
          // clear address errors as routable.
          this.addressErrors = null;
          this.preventPlaning = false;
        } else if (quickCheckRes === QuickTripCheckReturn.unconnected_regions) {
          this.addressErrors = "One or more stops are in unconnected regions";
          this.preventPlaning = true;
        } else if (quickCheckRes === QuickTripCheckReturn.not_routable) {
          this.addressErrors = "One or more stops cannot be reached";
          this.preventPlaning = true;
        }
        this.addressLoading = false;
      }
    },
    async fetching(val) {
      if (!val) {
        if (this.trip) this.planning = false;
        if (!this.trip) await this.buildTripFromQuery();
      }
    },
  },
});
</script>
<style scoped>
.pwt-info-panel-left-to-right-animation {
  left: -41%;
  -webkit-animation: left-to-right 700ms linear forwards;
  animation: left-to-right 700ms linear forwards;
}

.pwt-info-panel-right-to-left-animation {
  left: 0;
  -webkit-animation: right-to-left 1s linear forwards;
  animation: right-to-left 1s linear forwards;
}

@-webkit-keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

@keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}
</style>

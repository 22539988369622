import { render, staticRenderFns } from "./InfoPanelControls.vue?vue&type=template&id=0a44a654&scoped=true"
import script from "./InfoPanelControls.vue?vue&type=script&lang=ts"
export * from "./InfoPanelControls.vue?vue&type=script&lang=ts"
import style0 from "./InfoPanelControls.vue?vue&type=style&index=0&id=0a44a654&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a44a654",
  null
  
)

export default component.exports
<template>
  <v-card-text>
    <v-card class="rounded-lg mb-3 primary white--text">
      <v-expansion-panels flat v-model="expanded">
        <v-expansion-panel class="transparent">
          <v-expansion-panel-header class="pl-0" color="transparent">
            <v-card flat class="transparent">
              <v-card-title class="white--text pt-0 px-5 pb-1">
                Long term savings estimate
              </v-card-title>
              <v-card-title class="text-body-1 font-weight-medium pt-0">
                <v-icon color="white"> mdi-currency-usd </v-icon>
                <span class="pwt-number-font white--text">
                  {{
                    (
                      (oneYearProjections.totalEquivalentPetrolCost +
                        (calcVs === "PETROL"
                          ? oneYearProjections.totalPetrolRoadUserCharges
                          : oneYearProjections.totalDieselRoadUserCharges) +
                        oneYearProjections.totalPetrolMaintenanceCost -
                        oneYearProjections.totalPublicChargingCost -
                        oneYearProjections.totalPrivateChargingCost -
                        oneYearProjections.totalEVMaintenanceCost -
                        oneYearProjections.totalElectricRoadUserCharges) *
                      years
                    ).toLocaleString()
                  }}
                </span>
              </v-card-title>
              <v-card-subtitle class="pl-6 white--text">
                Total {{ years }} year{{ years > 1 ? "s" : "" }} savings
              </v-card-subtitle>
            </v-card>
            <template v-slot:actions>
              <v-icon color="white"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-slider
              min="1"
              max="10"
              step="1"
              v-model="years"
              dense
              hide-details
              color="white"
              :track-color="darkBlue"
              class="mb-5"
            >
              <template v-slot:label>
                <span class="white--text">
                  {{ years }} year{{ years > 1 ? "s" : "" }}
                </span>
              </template>
            </v-slider>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> $stationsIcon </v-icon> Fuel savings
                </span>
                <span class="pwt-number-font">
                  ${{
                    (
                      (oneYearProjections.totalEquivalentPetrolCost +
                        oneYearProjections.totalPublicChargingCost -
                        oneYearProjections.totalPrivateChargingCost) *
                      years
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Total equivalent {{ calcVs.toLowerCase() }} cost</span>
                <span class="pwt-number-font">
                  ${{
                    (
                      oneYearProjections.totalEquivalentPetrolCost * years
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li
                v-if="calcVs === 'DIESEL'"
                class="d-flex justify-space-between"
              >
                <span>Total equivalent diesel road user charges</span>
                <span class="pwt-number-font">
                  ${{
                    (
                      oneYearProjections.totalRoadUserCharges * years
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Total public charging cost</span>
                <span class="pwt-number-font">
                  ${{
                    (
                      oneYearProjections.totalPublicChargingCost * years
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Total private charging cost</span>
                <span class="pwt-number-font">
                  ${{
                    (
                      oneYearProjections.totalPrivateChargingCost * years
                    ).toLocaleString()
                  }}
                </span>
              </li>
            </ul>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> mdi-wrench </v-icon>
                  Maintenance savings
                </span>
                <span class="pwt-number-font">
                  ${{
                    (
                      (oneYearProjections.totalPetrolMaintenanceCost -
                        oneYearProjections.totalEVMaintenanceCost) *
                      years
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>
                  Estimated {{ calcVs.toLowerCase() }} vehicle maintenance cost
                </span>
                <span class="pwt-number-font">
                  ${{
                    (
                      oneYearProjections.totalPetrolMaintenanceCost * years
                    ).toLocaleString()
                  }}
                </span>
              </li>
              <li class="d-flex justify-space-between">
                <span>Estimated EV maintenance cost</span>
                <span class="pwt-number-font">
                  ${{
                    (
                      oneYearProjections.totalEVMaintenanceCost * years
                    ).toLocaleString()
                  }}
                </span>
              </li>
            </ul>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> mdi-leaf </v-icon>
                  Emissions avoided
                </span>
                <span class="pwt-number-font">
                  {{
                    (
                      oneYearProjections.totalAvoidedEmissions * years
                    ).toLocaleString()
                  }}
                  kg
                </span>
              </li>
            </ul>
            <ul
              style="list-style-type: none"
              class="ml-0 pl-0 mb-5 white--text"
            >
              <li class="d-flex justify-space-between">
                <span class="font-weight-bold">
                  <v-icon color="white"> $vehiclesIcon </v-icon>
                  Total trips
                </span>
                <span class="pwt-number-font">
                  {{ oneYearProjections.totalNumberOfTrips.toLocaleString() }}
                </span>
              </li>
            </ul>
            <div class="mb-5 white--text">
              The {{ years }} year savings are projected based on the expected
              frequency of your saved trips and your current saving settings.
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-card-text>
</template>
<script lang="ts">
import { powerTripDarkBlue } from "@/logic/data/const";
import { GettersTypes, type State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "FrequentTripSavings",
  computed: {
    ...mapGetters({
      oneYearProjections: GettersTypes.getFiveYearProjections,
    }),
    ...mapState({
      calcVs: (state: unknown) => (state as State).calcVs,
    }),
  },
  data() {
    return {
      expanded: false,
      years: 5,
      darkBlue: powerTripDarkBlue,
    };
  },
  watch: {
    expanded(val: 0 | undefined) {
      if (val === 0) {
        // Notify analytics server
        // eslint-disable-next-line
        // @ts-ignore
        Vue.prototype.$Countly.q.push([
          "add_event",
          {
            key: "5yr Savings Viewed",
            count: 1,
          },
        ]);
      }
    },
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>

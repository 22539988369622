import Charger from "../classes/charger_classes/charger";
import Trip from "../classes/trip_classes/trip";
import Vehicle from "../classes/vehicle_classes/vehicle";
import backupData from "../data/standardEfficiencyDefaultData";

/**
 * Returns a list of fleet vehicles in order of their best fit with the current selected trip.
 *
 * @param trip the whole `StoredTrip` object.
 * @param evModels the full list of `EVModel` objects.
 * @param vehicles the full list of the fleets `VehicleData` objects.
 * @param allowedChargingTime the user defined max charging time (defaults to 1hr).
 * @returns a list of objects in order of ranking, each object contains the a `VehicleData` object and its corresponding `EVModel` object.
 *
 * NOTE: only EVs are suggested not petrol vehicles.
 */
export default function recommendEV(
  trip: Trip,
  vehicles: Vehicle[],
  allowedChargingTime = 1,
  chargers: Charger[]
): recommendEVReturn {
  // Calculate trip efficiency once a trip is planned depending on trip format.
  const totalDistance = trip.itinerary.totalDrivingDistance / 1000; // in km
  const totalEnergy = trip.itinerary.totalEnergyUsed;

  // trip efficiency (Et) = trip distance (d) / trip energy.
  const tripEfficiency = totalDistance / totalEnergy;
  const vehicleChargingTimeList: {
    vehicle: Vehicle;
    chargingTime: number;
    batteryCapacity: number;
  }[] = [];
  const chargeRatings = getChargeRatings(trip, chargers, vehicles);
  const highestChargerRating = Math.max(...chargeRatings, 50);
  let minChargeTime: number | undefined = undefined;
  let maxChargeTime: number | undefined = undefined;

  // Get data from available cars
  vehicles.forEach((vehicle) => {
    // Get standard efficiency (Es)
    const standardEfficiency: number | undefined =
      backupData.find((e) => e.id === vehicle.eVModelId)?.standardEfficiency ??
      vehicle.evModel?.equivalentFuelEfficiency;
    if (standardEfficiency && vehicle.evModel && trip.SOCAct) {
      // multiplier (M) = Et / Es for the vehicle the trip was planned with.
      const multiplier = tripEfficiency / standardEfficiency;
      let lowestRating;

      if (vehicle.evModel.maxElectricPowerDC) {
        lowestRating = Math.min(
          vehicle.evModel.maxElectricPowerDC,
          highestChargerRating
        );
      } else if (vehicle.evModel.maxElectricPowerAC) {
        lowestRating = Math.min(
          vehicle.evModel.maxElectricPowerAC,
          highestChargerRating
        );
      } else {
        lowestRating = highestChargerRating;
      }

      const batteryCapacity =
        vehicle.evModel.batterySize *
        (vehicle.userProvidedStateOfHealth ??
          getLinearDegradationSOH(vehicle.evModel.year)) *
        trip.SOCAct;

      // Charging time (t) = MAX(M * (1/Es) * d -B * SoH * SoC , 0) / MIN( DC, MAX(Power rating from each scheduled charge stop along the route, 50) ),
      // NOTE: abbreviations used in above equation:
      // - standard efficiency (Es),
      // - battery size (B),
      // - State of Health (SoH),
      // - State of Charge (SoC),
      // - vehicles max DC charging power (DC),
      // - trip distance (d),
      // - multiplier (M) = Et / Es for the vehicle the trip was planned with.
      // - trip efficiency (Et) = trip distance (d) / trip energy.
      // - standard efficiency (Es), not calculated here provided by Richard as part of a external calculation.
      // ASSUMES: in cases where a vehicle is only rated for AC charging not DC this can be used instead of the DC value
      // as EVNav would have already taken this into account on charger selection for the trip.
      const chargingTime =
        Math.max(
          multiplier * (1 / standardEfficiency) * totalDistance -
            batteryCapacity,
          0
        ) / lowestRating;

      vehicleChargingTimeList.push({
        vehicle,
        chargingTime, // in hours
        batteryCapacity,
      });

      if (!minChargeTime) {
        minChargeTime = chargingTime;
      } else if (chargingTime < minChargeTime) {
        minChargeTime = chargingTime;
      }

      if (!maxChargeTime) {
        maxChargeTime = chargingTime;
      } else if (chargingTime > maxChargeTime) {
        maxChargeTime = chargingTime;
      }

      // handle no charging stops in trip
    }
  });

  // find difference between calculated charging time and allowed charging time.
  const vehicleDiffList: {
    vehicle: Vehicle;
    chargingTime: number;
    batteryCapacity: number;
    difference: number;
  }[] = [];

  if (vehicleChargingTimeList.every((e) => e.chargingTime === 0)) {
    vehicleChargingTimeList.forEach((e) => {
      if (e.batteryCapacity >= totalEnergy) {
        vehicleDiffList.push({
          ...e,
          difference: e.batteryCapacity - totalEnergy,
        });
      }
    });
  } else {
    vehicleChargingTimeList.forEach((e) => {
      vehicleDiffList.push({
        ...e,
        difference:
          Math.max(allowedChargingTime, e.chargingTime) -
          Math.min(allowedChargingTime, e.chargingTime),
      });
    });
  }

  // Order based on least difference between calculated charging time and allowed charging time.
  const rankedList = vehicleDiffList.sort((a, b) => {
    if (a.difference > b.difference) {
      return 1;
    }
    if (a.difference < b.difference) {
      return -1;
    }
    return 0;
  });

  // Return usable data in order of ranking
  return {
    rankedList: rankedList.map((e) => ({
      vehicle: e.vehicle,
    })),
    min: minChargeTime ?? 0,
    max: maxChargeTime ?? 2,
  };
}

// Helper function that returns the SOH for a vehicle as a decimal representation of a percentage eg: 80% = 0.8.
// based on linear degradation.
function getLinearDegradationSOH(year: number): number {
  return 1 - (new Date().getFullYear() - year) * 0.03;
}

// Helper function that filters all charging stops of a trip and returns an array of the max kw rating the charger can provide.
function getChargeRatings(
  trip: Trip,
  chargers: Charger[],
  vehicles: Vehicle[]
): number[] {
  const returnArray: number[] = [];

  // NOTE: only successfully planned EV trips have charging stops calculated.
  if (trip.status === "success") {
    trip.itinerary.steps.forEach((step) => {
      if (step.locationCDBID) {
        const charger = chargers.find((c) => c.id === step.locationCDBID);
        if (!charger) return;
        const ratings = vehicles.map((v) => {
          return charger.bestCompatibleConnector(v)?.maxElectricPower;
        });
        const filteredRatings = ratings.filter((r) => r !== undefined);
        if (filteredRatings.length)
          returnArray.push(Math.max(...filteredRatings) / 1000);
      }
    });
  }

  return returnArray;
}

export interface recommendEVReturn {
  rankedList: {
    vehicle: Vehicle;
  }[];
  min: number;
  max: number;
}

<template>
  <v-card flat class="px-16 pb-16 pt-8" v-if="planning">
    <v-card-text class="primary--text text-center text-h5">
      Power trip is calculating your comparison
    </v-card-text>
    <v-card-text class="d-flex justify-center primary--text text-center">
      <LoadingCard />
    </v-card-text>
    <v-card-text class="primary--text text-center text-subtitle-2">
      This may take a few moments
    </v-card-text>
  </v-card>
  <v-card flat v-else>
    <v-card-title> Vehicle Comparisons </v-card-title>
    <v-card-subtitle>
      Add vehicles to review there performance along this route
    </v-card-subtitle>
    <v-card-text>
      Vehicles you are comparing
      <v-slide-group show-arrows class="pt-2">
        <v-slide-item
          v-for="(comparison, index) in trip.comparisons"
          :key="'comparison-' + index"
          style="width: 150px"
          class="pr-2"
          :class="index == 0 ? 'pl-1' : ''"
        >
          <ComparisonCard
            :comparison="comparison"
            :isShowing="comparison.localId === trip.displayedComparisonId"
            @showMe="showComparison(comparison.localId)"
          />
        </v-slide-item>
      </v-slide-group>
      <v-row no-gutters>
        <v-spacer />
        <ElevatedBtn @click="() => (showDialog = true)">
          add comparison vehicle
        </ElevatedBtn>
      </v-row>
    </v-card-text>
    <div v-if="trip.displayedComparisonData">
      <v-card-title> Performance summary </v-card-title>
      <v-card-text>
        <v-card-subtitle class="pl-0"> Travel details </v-card-subtitle>
        <ul class="pl-0 mb-5" style="list-style-type: none">
          <li>
            <span class="font-weight-bold">
              {{
                Math.round(
                  trip.displayedComparisonData.drivingDistance / 1000
                ) + " km"
              }}
            </span>
            distance to travel
          </li>
          <li>
            <span class="font-weight-bold">
              {{ niceDuration(trip.displayedComparisonData.drivingTime) }}
            </span>
            driving time
          </li>
        </ul>
        <v-card-subtitle class="pl-0"> Charging Time </v-card-subtitle>
        <ul class="pl-0 mb-5" style="list-style-type: none">
          <li>
            <span class="font-weight-bold">
              {{
                niceDuration(
                  trip.displayedComparisonData.estimatedChargingTime
                    .chargingTimeAtMin
                )
              }}
            </span>
            charging time at a rate of
            <span class="font-weight-bold">
              {{
                Math.round(
                  trip.displayedComparisonData.estimatedChargingTime
                    .minChargeRate
                )
              }}kw
            </span>
          </li>
          <li>
            <span class="font-weight-bold">
              {{
                niceDuration(
                  trip.displayedComparisonData.estimatedChargingTime
                    .chargingTimeAtMax
                )
              }}
            </span>
            charging time at a rate of
            <span class="font-weight-bold">
              {{
                Math.round(
                  trip.displayedComparisonData.estimatedChargingTime
                    .maxChargeRate
                )
              }}kw
            </span>
          </li>
        </ul>
        <v-card-subtitle class="pl-0"> Battery Usage </v-card-subtitle>
        <ul class="pl-0 mb-5" style="list-style-type: none">
          <li>
            <span class="font-weight-bold">
              {{ Math.round(trip.displayedComparisonData.totalEnergy) }} kWh
            </span>
            needed to complete this trip
          </li>
          <li>
            <span class="font-weight-bold">
              {{
                Math.round(trip.displayedComparisonData.totalChargeUsed * 100)
              }}
              %
            </span>
            of this vehicles battery capacity
          </li>
          <li>
            <span class="font-weight-bold">
              {{
                numOfStopsDisplayStringBoldSection(
                  trip.displayedComparisonData.numberOfChargingStopsNeeded
                )
              }}
            </span>
            {{
              numberOfStopsDisplayStringRegularSection(
                trip.displayedComparisonData.numberOfChargingStopsNeeded
              )
            }}
          </li>
        </ul>
        <v-card-subtitle class="pl-0"> Cost Estimates </v-card-subtitle>
        <ul class="pl-0 mb-5" style="list-style-type: none">
          <li>
            <span class="font-weight-bold">
              ${{ calcPrivateChargingCost() }}
            </span>
            home charging cost at max rate
          </li>
          <li>
            <span class="font-weight-bold">
              ${{ calcPublicChargingCost() }}
            </span>
            fast charging cost at max rate
          </li>
          <li>
            <span class="font-weight-bold">
              ${{ calcEquivalentFuelCost() }}
            </span>
            equivalent fuel cost
          </li>
        </ul>
        <ul class="pl-0 mb-5" style="list-style-type: none">
          <li>
            <span class="font-weight-bold">
              ${{ calcEquivalentFuelCost() - calcPublicChargingCost() }}
            </span>
            estimates fuel savings
          </li>
          <li>
            <span class="font-weight-bold"> ${{ calcRoadUserCharges() }} </span>
            road user charges
          </li>
        </ul>
      </v-card-text>
    </div>
    <v-card-actions>
      <ElevatedBlockBtn @click="emitUseCurrentComparison">
        Use this vehicle for the trip
      </ElevatedBlockBtn>
    </v-card-actions>
    <!-- dialog -->
    <GenericDialog :activator="showDialog" @close="showDialog = false">
      <VehicleSearchContent @vehicle-select="addComparison" />
    </GenericDialog>
  </v-card>
</template>

<script lang="ts">
import {
  CalcVsType,
  GettersTypes,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import ElevatedBlockBtn from "../../ui-elements/buttons/ElevatedBlockBtn.vue";
import ComparisonCard from "../statistics/info-panel-content/ComparisonCard.vue";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import to2DP from "@/logic/utils/to2DP";
import GenericDialog from "../../dialog-wrappers/GenericDialog.vue";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
import VehicleSearchContent from "@/ui/components/trips/comparison/VehicleSearchContent.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import LoadingCard from "../../ui-elements/loaders/LoadingCard.vue";

export default Vue.extend({
  name: "ComparisonContent",
  components: {
    ElevatedBlockBtn,
    ElevatedBtn,
    ComparisonCard,
    GenericDialog,
    VehicleSearchContent,
    LoadingCard,
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      publicChargingCostPerKW: (state: unknown) =>
        (state as State).defaultPublicCostPerKWh,
      publicChargingCostPerMin: (state: unknown) =>
        (state as State).defaultCostPerMinDC,
      privateChargingCost: (state: unknown) =>
        (state as State).defaultHomeCostPerKWh,
      fuelCostPerLitre: (state: unknown) => {
        if ((state as State).calcVs === CalcVsType.PETROL) {
          return (state as State).petrolCostPerLitre;
        }
        return (state as State).dieselCostPerLitre;
      },
      kmPerLitre: (state: unknown) => {
        if ((state as State).calcVs === CalcVsType.PETROL) {
          return (state as State).petrolKmPerLitre;
        }
        return (state as State).dieselKmPerLitre;
      },
      roadUserChargeCost: (state: unknown) =>
        (state as State).dieselRoadUserCharges,
    }),
  },
  data() {
    return {
      showDialog: false,
      planning: false,
    };
  },
  methods: {
    showComparison(localId: string) {
      const updatedTrip = this.trip;
      updatedTrip.displayedComparisonId = localId;
      this.$store.commit(MutationTypes.updateIndividualTrip, updatedTrip);
    },
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    numOfStopsDisplayStringBoldSection(num: number): string {
      if (num < 1) return "less than 1";
      if (num % 1 === 0) return num.toString();
      return Math.floor(num) + " to " + Math.ceil(num) + " ";
    },
    numberOfStopsDisplayStringRegularSection(num: number): string {
      if (num < 1) return "charge required";
      if (num === 1) return "charging stop required";
      return "charging stops required";
    },
    calcPrivateChargingCost(): number {
      return to2DP(
        this.trip.displayedComparisonData.homeChargingCost(
          this.privateChargingCost
        )
      );
    },
    calcPublicChargingCost(): number {
      return to2DP(
        this.trip.displayedComparisonData.fastChargingCost({
          publicChargingCost: this.publicChargingCostPerKW,
          publicChargingCostPerMin: this.publicChargingCostPerMin,
        })
      );
    },
    calcEquivalentFuelCost(): number {
      return to2DP(
        this.trip.displayedComparisonData.equivalentFuelCost({
          fuelCostPerLitre: this.fuelCostPerLitre,
          kmPerLitre: this.kmPerLitre,
        })
      );
    },
    calcRoadUserCharges(): number {
      return to2DP(
        this.trip.displayedComparisonData.roadUserCharges(
          this.roadUserChargeCost
        )
      );
    },
    async addComparison(vehicle: Vehicle) {
      this.showDialog = false;
      this.planning = true;
      if (this.trip) {
        await this.trip.addComparison(vehicle);
      }
      this.planning = false;
    },
    emitUseCurrentComparison() {
      this.$emit("use-comparison");
    },
  },
});
</script>

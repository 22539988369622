<template>
  <v-card flat class="background">
    <v-card-text>
      <v-row>
        <v-btn icon @click="$emit('back')">
          <v-icon color="primary">mdi-chevron-left</v-icon>
        </v-btn>
        <span v-for="(item, index) in items" :key="item.text">
          <span v-if="index > 0" class="grey--text font-weight-bold"> / </span>
          <v-btn
            class="text-none"
            text
            @click="handelNavigation(item)"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-btn>
        </span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

export interface BreadcrumbsItem {
  text: string;
  disabled?: boolean;
}

export default Vue.extend({
  name: "DashboardBreadcrumbs",
  props: {
    items: {
      type: Array as PropType<BreadcrumbsItem[]>,
      required: true,
    },
  },
  methods: {
    handelNavigation(item: BreadcrumbsItem) {
      this.$emit("navigation", item);
    },
  },
});
</script>

<template>
  <!-- planning animation page -->
  <InfoPanelCardWrapper v-if="planning">
    <DescriptiveLoader message="Power Trip is planning your trip" />
  </InfoPanelCardWrapper>
  <!-- planning form -->
  <InfoPanelCardWrapper v-else style="padding-bottom: 100px !important">
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <!-- locations list section -->
    <LocationsListRow
      :addressData="originAddressData"
      id="origin"
      @update="updateAddress"
      type="origin"
      :loading="addressLoading"
      :readonly="true"
    />
    <LocationsListRow
      v-for="stop in waypoints"
      :key="stop.local_id"
      :addressData="{
        address: stop.address,
        ...stop.coordinates.asCapitalizedObj,
      }"
      :id="stop.local_id"
      @update="updateAddress"
      type="stop"
      :loading="addressLoading"
      :errorMsg="addressErrors"
    />
    <LocationsListRow
      :addressData="destinationAddressData"
      id="destination"
      @update="updateAddress"
      type="destination"
      :loading="addressLoading"
      :readonly="true"
    />
    <v-card-title class="pl-0"> Add Stops </v-card-title>
    <WaypointPlanningCard
      @add-stop="handleAddWaypoint"
      :remainingLoad="remainingLoad"
      :originCoordinates="
        originAddressData
          ? {
              lat: originAddressData.coordinates.Latitude,
              lon: originAddressData.coordinates.Longitude,
            }
          : { lat: 0, lon: 0 }
      "
      :destinationCoordinates="
        destinationAddressData
          ? {
              lat: destinationAddressData.coordinates.Latitude,
              lon: destinationAddressData.coordinates.Longitude,
            }
          : { lat: 0, lon: 0 }
      "
    />
    <!-- Actions section -->
    <div
      class="pl-5 pr-7"
      style="
        position: fixed;
        top: calc(100dvh - 50px);
        left: 0;
        width: 40%;
        max-width: 600px;
      "
    >
      <div class="background rounded-lg">
        <ElevatedBlockBtn
          @click="handleCreateItinerary"
          class="mb-3"
          :disabled="preventPlaning || addressLoading"
          :loading="addressLoading"
        >
          Create Trip Itinerary
        </ElevatedBlockBtn>
      </div>
    </div>
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import ElevatedBlockBtn from "../components/ui-elements/buttons/ElevatedBlockBtn.vue";
import InfoPanelCardWrapper from "../components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import queryValueToString from "@/logic/utils/queryValueToString";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import LocationsListRow from "../components/trips/planning/LocationsListRow.vue";
import { AddressAutocompleteInputUpdateObj } from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import WaypointPlanningCard from "../components/trips/planning/WaypointPlanningCard.vue";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import Trip from "@/logic/classes/trip_classes/trip";
import {
  ActionTypes,
  GettersTypes,
  // MutationTypes,
  State,
} from "@/logic/store/store_types";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { Dictionary, RawLocation } from "vue-router/types/router";
import TripsHomeBtn from "../components/ui-elements/buttons/TripsHomeBtn.vue";
import DescriptiveLoader from "../components/ui-elements/loaders/DescriptiveLoader.vue";
import { quickTripCheck } from "@/logic/api/calls/valhalla_calls";
import { QuickTripCheckReturn } from "@/logic/types/valhalla_types";

export default Vue.extend({
  name: "PlanningAddStopsView",
  components: {
    ElevatedBlockBtn,
    InfoPanelCardWrapper,
    LocationsListRow,
    WaypointPlanningCard,
    TripsHomeBtn,
    DescriptiveLoader,
  },
  data() {
    return {
      // address list section data
      originAddressData: undefined as processedAddressObj | undefined,
      destinationAddressData: undefined as processedAddressObj | undefined,
      waypoints: [] as TripLocation[],
      remainingLoad: 0,
      // control
      planning: false,
      addressLoading: false,
      addressErrors: null as string | null,
      preventPlaning: false,
    };
  },
  computed: {
    items(): { text: string; to: RawLocation }[] {
      if ((this.$store.state as State).selectedTrip)
        return [
          {
            text: "Add Destination",
            to: { name: RouteNames.tripAddDestination },
          },
          {
            text: "Add Origin",
            to: { name: RouteNames.tripAddOrigin },
          },
          {
            text: "Add Details",
            to: { name: RouteNames.tripAddDetails },
          },
          {
            text: "Add Stops",
            to: { name: RouteNames.tripAddStops },
          },
          {
            text: "Itinerary",
            to: { name: RouteNames.tripItinerary },
          },
        ];
      return [
        {
          text: "Add Destination",
          to: { name: RouteNames.tripAddDestination },
        },
        {
          text: "Add Origin",
          to: { name: RouteNames.tripAddOrigin },
        },
        {
          text: "Add Details",
          to: { name: RouteNames.tripAddDetails },
        },
        {
          text: "Add Stops",
          to: { name: RouteNames.tripAddStops },
        },
      ];
    },
  },
  methods: {
    // Routing
    pushRoute(
      routeName: RouteNames,
      query:
        | Dictionary<string | (string | null)[] | null | undefined>
        | undefined = undefined
    ) {
      this.$router.push({ name: routeName, query: query });
    },
    toItinerary() {
      this.pushRoute(RouteNames.tripItinerary);
    },
    updateAddress(address: AddressAutocompleteInputUpdateObj) {
      if (address.id === "origin") {
        this.originAddressData = address.addressData;
        this.updateRouteQuery();
      } else if (address.id === "destination") {
        this.destinationAddressData = address.addressData;
        this.updateRouteQuery();
      } else {
        // ASSUMES: is a waypoint
        if (address.addressData) {
          // update waypoint
          const newWaypoint = new TripLocation({
            local_id: address.id,
            name: address.addressData.name,
            address: address.addressData.address,
            coordinates: new Coordinate({
              latitude: address.addressData.coordinates.Latitude,
              longitude: address.addressData.coordinates.Longitude,
            }),
          });
          const copyWaypoints = [...this.waypoints];
          const index = copyWaypoints.findIndex(
            (waypoint) => waypoint.local_id === address.id
          );
          if (index === -1) return;
          copyWaypoints[index] = newWaypoint;
          this.waypoints = copyWaypoints;
        } else {
          // remove waypoint
          const copyWaypoints = [...this.waypoints];
          const index = copyWaypoints.findIndex(
            (waypoint) => waypoint.local_id === address.id
          );
          if (index === -1) return;
          copyWaypoints.splice(index, 1);
          this.waypoints = copyWaypoints;
        }
        this.updateRouteQuery();
      }
    },
    updateRouteQuery() {
      const newQuery = {
        ...this.$route.query,
      };

      if (this.destinationAddressData) {
        newQuery.destAddress = encodeURI(this.destinationAddressData.address);
        newQuery.destLat =
          this.destinationAddressData.coordinates.Latitude.toString();
        newQuery.destLon =
          this.destinationAddressData.coordinates.Longitude.toString();
        if (this.destinationAddressData.name) {
          newQuery.destName = this.destinationAddressData.name;
        } else {
          delete newQuery.destName;
        }
      } else {
        delete newQuery.destAddress;
        delete newQuery.destLat;
        delete newQuery.destLon;
        delete newQuery.destName;
      }

      if (this.originAddressData) {
        newQuery.origAddress = encodeURI(this.originAddressData.address);
        newQuery.origLat =
          this.originAddressData.coordinates.Latitude.toString();
        newQuery.origLon =
          this.originAddressData.coordinates.Longitude.toString();
        if (this.originAddressData.name) {
          newQuery.origName = this.originAddressData.name;
        } else {
          delete newQuery.origName;
        }
      } else {
        delete newQuery.origAddress;
        delete newQuery.origLat;
        delete newQuery.origLon;
        delete newQuery.origName;
      }

      // rebuild stops

      Object.keys(newQuery)
        .filter((key) => key.startsWith("stop"))
        .forEach((key) => {
          delete newQuery[key];
        }); // remove old stops

      this.waypoints.forEach((stop, index) => {
        newQuery[`stop${index + 1}Address`] = encodeURI(stop.address);
        newQuery[`stop${index + 1}Lat`] = stop.coordinates.latitude.toString();
        newQuery[`stop${index + 1}Lon`] = stop.coordinates.longitude.toString();
        if (stop.name) newQuery[`stop${index + 1}Name`] = encodeURI(stop.name);
        if (stop.stay) newQuery[`stop${index + 1}Stay`] = stop.stay.toString();
        if (stop.stateOfChargeAfterCharging)
          newQuery[`stop${index + 1}LeavingSoC`] =
            stop.stateOfChargeAfterCharging.toString();
        if (stop.weightChange)
          newQuery[`stop${index + 1}Weight`] = stop.weightChange.toString();
        if (stop.nonDrivingEnergyUsed)
          newQuery[`stop${index + 1}UsedEnergy`] =
            stop.nonDrivingEnergyUsed.toString();
      }); // add new stops

      // check if nothing changed
      if (
        Object.keys(newQuery).every(
          (key) => newQuery[key] === this.$route.query[key]
        ) &&
        Object.keys(this.$route.query).every(
          (key) => newQuery[key] === this.$route.query[key]
        )
      )
        return; // nothing to update

      // update query
      this.$router.replace({
        query: newQuery,
      });
    },
    handleAddWaypoint(val: TripLocation) {
      this.waypoints.push(val);
      this.updateRouteQuery();
    },
    async handleCreateItinerary() {
      // guard clauses
      if (!this.originAddressData) {
        // TODO: show error
        return;
      }

      if (!this.destinationAddressData) {
        // TODO: show error
        return;
      }

      // prep locations
      const locations: TripLocation[] = [];
      // add origin
      locations.push(
        new TripLocation({
          address: this.originAddressData.address,
          coordinates: new Coordinate({
            latitude: this.originAddressData.coordinates.Latitude,
            longitude: this.originAddressData.coordinates.Longitude,
          }),
          name: this.originAddressData.name,
        })
      );
      // add stops
      locations.push(...this.waypoints);
      // add destination
      locations.push(
        new TripLocation({
          address: this.destinationAddressData.address,
          coordinates: new Coordinate({
            latitude: this.destinationAddressData.coordinates.Latitude,
            longitude: this.destinationAddressData.coordinates.Longitude,
          }),
          name: this.destinationAddressData.name,
        })
      );
      // create trip
      const trip = new Trip({
        locations: locations,
        roundTripFlag: false,
      });
      trip.SOCAct =
        queryValueToNumber(this.$route.query.SOCAct) ??
        (this.$store.state as State).SOCAct;
      trip.SOCEnd =
        queryValueToNumber(this.$route.query.SOCEnd) ??
        (this.$store.state as State).SOCEnd;
      trip.SOCMin =
        queryValueToNumber(this.$route.query.SOCMin) ??
        (this.$store.state as State).SOCMin;
      trip.SOCMax =
        queryValueToNumber(this.$route.query.SOCMax) ??
        (this.$store.state as State).SOCMax;
      trip.SpeedAdjustment = queryValueToNumber(this.$route.query.speedAdj);
      trip.startingLoad =
        queryValueToNumber(this.$route.query.extraWeight) ?? 0;
      trip.passengers = queryValueToNumber(this.$route.query.passengers) ?? 0;
      trip.vehicle = this.$store.getters[GettersTypes.selectedVehicleData] as
        | Vehicle
        | undefined;
      // plan trip
      this.planning = true;
      await trip.planTrip();
      this.$store.dispatch(ActionTypes.showTrip, trip);
      this.planning = false;
    },
  },
  mounted() {
    if (this.$route.query.destAddress) {
      const address = queryValueToString(this.$route.query.destAddress);
      const lat = queryValueToNumber(this.$route.query.destLat);
      const lon = queryValueToNumber(this.$route.query.destLon);
      const name = queryValueToString(this.$route.query.destName);
      if (address && lat && lon) {
        this.destinationAddressData = {
          address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name,
        };
      }
    }

    if (this.$route.query.origAddress) {
      const address = queryValueToString(this.$route.query.origAddress);
      const lat = queryValueToNumber(this.$route.query.origLat);
      const lon = queryValueToNumber(this.$route.query.origLon);
      const name = queryValueToString(this.$route.query.origName);
      if (address && lat && lon) {
        this.originAddressData = {
          address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name,
        };
      }
    }

    let remainingLoad = queryValueToNumber(this.$route.query.extraWeight) ?? 0;

    const stopKeys = Object.keys(this.$route.query).filter((key) =>
      key.startsWith("stop")
    );
    let groupedKeys = [];
    for (let i = 0; i < stopKeys.length; i++) {
      const key = stopKeys[i];
      const stopNumber = parseIntOrFloat(key[4]); // ASSUMES pattern of `stop[stop index + 1][stop property key]` is still being used
      if (!stopNumber) continue;
      if (!groupedKeys[stopNumber - 1]) groupedKeys[stopNumber - 1] = [key];
      else groupedKeys[stopNumber - 1].push(key);
    }
    for (let index = 0; index < groupedKeys.length; index++) {
      const group = groupedKeys[index];
      const newWaypoint = new TripLocation();
      for (let i = 0; i < group.length; i++) {
        const key = group[i];
        if (key === `stop${index + 1}Address`) {
          const parsedAddress = queryValueToString(this.$route.query[key]);
          if (parsedAddress) newWaypoint.address = parsedAddress;
        }
        if (key === `stop${index + 1}Lat`) {
          const parsedLat = queryValueToNumber(this.$route.query[key]);
          if (parsedLat) newWaypoint.coordinates.latitude = parsedLat;
        }
        if (key === `stop${index + 1}Lon`) {
          const parsedLon = queryValueToNumber(this.$route.query[key]);
          if (parsedLon) newWaypoint.coordinates.longitude = parsedLon;
        }
        if (key === `stop${index + 1}Name`) {
          const parsedName = queryValueToString(this.$route.query[key]);
          if (parsedName) newWaypoint.name = parsedName;
        }
        if (key === `stop${index + 1}Stay`) {
          const parsedStay = queryValueToNumber(this.$route.query[key]);
          if (parsedStay) newWaypoint.stay = parsedStay;
        }
        if (key === `stop${index + 1}LeavingSoC`) {
          const parsedSoC = queryValueToNumber(this.$route.query[key]);
          if (parsedSoC) newWaypoint.stateOfChargeAfterCharging = parsedSoC;
        }
        if (key === `stop${index + 1}Weight`) {
          const parsedWeight = queryValueToNumber(this.$route.query[key]);
          if (parsedWeight) {
            remainingLoad += parsedWeight;
            newWaypoint.weightChange = parsedWeight;
          }
        }
        if (key === `stop${index + 1}UsedEnergy`) {
          const parsedEnergy = queryValueToNumber(this.$route.query[key]);
          if (parsedEnergy) newWaypoint.nonDrivingEnergyUsed = parsedEnergy;
        }
      }
      this.waypoints.push(newWaypoint);
    }

    this.remainingLoad = remainingLoad;
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripAddStops ||
        to.name === RouteNames.tripAddDetails ||
        to.name === RouteNames.tripAddOrigin ||
        to.name === RouteNames.tripAddDestination ||
        to.name === RouteNames.tripStats) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
  watch: {
    async waypoints(val: TripLocation[]) {
      if (val.length === 0) {
        // ASSUMEs back to only origin and destination.
        // clear address errors as routable.
        this.addressErrors = null;
        this.preventPlaning = false;
        return;
      }
      if (val.length && val.every((location) => location.coordinates.isValid)) {
        this.addressLoading = true;
        const quickCheckRes = await quickTripCheck([
          {
            lat: this.originAddressData?.coordinates.Latitude ?? 0,
            lon: this.originAddressData?.coordinates.Longitude ?? 0,
          },
          ...val.map((location) => ({
            lat: location.coordinates.latitude,
            lon: location.coordinates.longitude,
          })),
          {
            lat: this.destinationAddressData?.coordinates.Latitude ?? 0,
            lon: this.destinationAddressData?.coordinates.Longitude ?? 0,
          },
        ]);
        if (quickCheckRes === QuickTripCheckReturn.routable) {
          // clear address errors as routable.
          this.addressErrors = null;
          this.preventPlaning = false;
        } else if (quickCheckRes === QuickTripCheckReturn.unconnected_regions) {
          this.addressErrors = "One or more stops are in unconnected regions";
          this.preventPlaning = true;
        } else if (quickCheckRes === QuickTripCheckReturn.not_routable) {
          this.addressErrors = "One or more stops cannot be reached";
          this.preventPlaning = true;
        }
        this.addressLoading = false;
      }
    },
  },
});
</script>

<template>
  <v-card flat>
    <v-card-text>{{ moduleName || "Unknown" }}</v-card-text>
    <StoredTripCard
      v-for="(trip, index) in groupedTrips.trips"
      :key="`stored-trip-card-${index}`"
      :tripDetail="trip"
    />
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import StoredTripCard from "./StoredTripCard.vue";
import { State } from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip_classes/trip";

interface SortedTripsGroup {
  evModel: string;
  trips: Trip[];
}

export default Vue.extend({
  name: "StoredTripsGroupCard",
  props: {
    groupedTrips: Object as PropType<SortedTripsGroup>,
  },
  computed: {
    moduleName(): string | undefined {
      return (this.$store.state as State).evModels?.find(
        (model) => model.id === this.groupedTrips.evModel
      )?.name;
    },
    ...mapState({}),
  },
  components: { StoredTripCard },
});
</script>

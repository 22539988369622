import { render, staticRenderFns } from "./StoredTripCard.vue?vue&type=template&id=807c24e6&scoped=true"
import script from "./StoredTripCard.vue?vue&type=script&lang=ts"
export * from "./StoredTripCard.vue?vue&type=script&lang=ts"
import style0 from "./StoredTripCard.vue?vue&type=style&index=0&id=807c24e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "807c24e6",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./DashboardDialog.vue?vue&type=template&id=0db5b6aa&scoped=true"
import script from "./DashboardDialog.vue?vue&type=script&lang=ts"
export * from "./DashboardDialog.vue?vue&type=script&lang=ts"
import style0 from "./DashboardDialog.vue?vue&type=style&index=0&id=0db5b6aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db5b6aa",
  null
  
)

export default component.exports
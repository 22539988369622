var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.charger)?_c('v-container',{staticClass:"pa-5"},[_c('v-container',{staticClass:"pt-0"},[_c('v-card-title',{staticClass:"pt-0 pl-0"},[_vm._v(" "+_vm._s(_vm.charger.name)+" "),(_vm.charger.operator && _vm.charger.operator.name)?_c('v-spacer'):_vm._e(),_c('OperatorLogo',{attrs:{"networkBranding":_vm.branding}})],1),(_vm.charger.addressString)?_c('v-card-subtitle',{staticClass:"pl-0"},[_vm._v(" "+_vm._s(_vm.charger.addressString)+" ")]):_c('v-card-subtitle',[_vm._v(" charging station address has not been provided ")]),_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"primary--text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-menu-down")])]},proxy:true}],null,false,2309637952)},[_vm._v(" Charging station devices ")]),_c('v-expansion-panel-content',[_c('EvseCard',{attrs:{"evses":_vm.charger.evses},on:{"selectConnector":_vm.selectConnector}})],1)],1)],1),(
        _vm.trip &&
        _vm.charger &&
        _vm.trip.status !== 'optimised-trip' &&
        _vm.trip.chargingStopCDBIDs.includes(_vm.charger.id)
      )?_c('ChargingStopDetailsCard'):(_vm.charger && _vm.vehicle && _vm.vehicle.evModel)?_c('ChargingCalculatorCard',{attrs:{"connector":_vm.selectedConnector}}):_c('v-card',{staticClass:"pa-2 mt-5 mb-5 rounded-lg"},[_c('v-card-text',[_vm._v(" Please set an EV profile for your EV to see estimates. ")])],1),(_vm.showAddChargingStopButton)?_c('v-card',{staticClass:"mb-5 rounded-lg"},[_c('v-card-title',[_vm._v(" Add charger to your trip ")]),_c('v-card-text',[_c('v-slider',{attrs:{"min":"1","max":"100","step":"1","track-color":"grey lighten-2","label":'Charge up to ' + _vm.chargeTo + '%'},model:{value:(_vm.chargeTo),callback:function ($$v) {_vm.chargeTo=$$v},expression:"chargeTo"}}),_c('v-list',{staticClass:"background"},[_c('v-subheader',[_vm._v("Add to leg")]),_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedStep),callback:function ($$v) {_vm.selectedStep=$$v},expression:"selectedStep"}},_vm._l((_vm.stepOrder),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.displayName)+" ")])],1)],1)}),1)],1),_c('ElevatedBlockBtn',{staticClass:"mt-5",on:{"click":_vm.addChargingStop}},[_vm._v(" Add charging stop ")])],1)],1):_vm._e(),_c('v-card',{staticClass:"pb-10",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Plugs and sockets ")]),_c('PlugsCard',{attrs:{"plugs":_vm.charger.allConnectors}}),_c('v-card-title',{staticClass:"primary--text"},[_vm._v("Photos added")]),_c('ChargingStationImageCarousel'),_c('ChargingStationsAnimatedTabsCard',{attrs:{"networkDisplayName":_vm.branding && _vm.branding.displayName ? _vm.branding.displayName : undefined,"instructions":_vm.branding && _vm.branding.instructions
            ? _vm.branding.instructions
            : undefined}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <InfoPanelCardWrapper>
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <!-- locations list section -->
    <AddressAutocompleteInput
      class="px-4 mb-5"
      @update="updateAddress"
      :initialValue="addressData"
      :allowFavLocations="true"
      placeholder="Search Optimiser for a location..."
    />
    <!-- destination confirmation section -->
    <DestinationPlanningCard
      @planTrip="toAddOrigin"
      :destination="addressData"
      :nearbyChargers="nearbyChargers"
    />
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import InfoPanelCardWrapper from "../components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import DestinationPlanningCard from "../components/trips/planning/DestinationPlanningCard.vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import queryValueToString from "@/logic/utils/queryValueToString";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import { RawLocation } from "vue-router";
import TripsHomeBtn from "../components/ui-elements/buttons/TripsHomeBtn.vue";
import Charger from "@/logic/classes/charger_classes/charger";
import haversineDistance from "@/logic/utils/haversineDistance";
import { State } from "@/logic/store/store_types";
export default Vue.extend({
  name: "PlanningAddDestinationView",
  components: {
    InfoPanelCardWrapper,
    DestinationPlanningCard,
    AddressAutocompleteInput,
    TripsHomeBtn,
  },
  data() {
    return {
      address: undefined as string | undefined,
      lat: undefined as number | undefined,
      lon: undefined as number | undefined,
      name: undefined as string | undefined,
    };
  },
  computed: {
    items(): { text: string; to: RawLocation }[] {
      if ((this.$store.state as State).selectedTrip)
        return [
          {
            text: "Add Destination",
            to: { name: RouteNames.tripAddDestination },
          },
          {
            text: "Add Origin",
            to: { name: RouteNames.tripAddOrigin },
          },
          {
            text: "Add Details",
            to: { name: RouteNames.tripAddDetails },
          },
          {
            text: "Add Stops",
            to: { name: RouteNames.tripAddStops },
          },
          {
            text: "Itinerary",
            to: { name: RouteNames.tripItinerary },
          },
        ];
      return [
        {
          text: "Add Destination",
          to: {
            name: RouteNames.tripAddDestination,
          },
        },
      ];
    },
    addressData(): processedAddressObj | undefined {
      if (!this.address || !this.lat || !this.lon) return;
      return {
        address: this.address,
        coordinates: {
          Latitude: this.lat,
          Longitude: this.lon,
        },
        name: this.name,
      };
    },
    nearbyChargers(): Charger[] {
      if (!this.lat || !this.lon) return [];
      const tempArray: Charger[] = [];
      const chargers: Charger[] = (this.$store.state as State).chargers;
      chargers.forEach((charger) => {
        if (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.chargerIsWithIn1Km({ charger, lat: this.lat!, lon: this.lon! })
        ) {
          tempArray.push(charger);
        }
      });
      return tempArray;
    },
  },
  methods: {
    // Routing
    back() {
      this.$router.back();
    },
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName, query: this.$route.query });
    },
    toAddOrigin() {
      this.pushRoute(RouteNames.tripAddOrigin);
    },
    updateAddress(address: AddressAutocompleteInputUpdateObj) {
      this.address = address.addressData?.address;
      this.lat = address.addressData?.coordinates.Latitude;
      this.lon = address.addressData?.coordinates.Longitude;
      this.name = address.addressData?.name;
      this.updateRouteQuery();
    },
    updateRouteQuery() {
      const newQuery = {
        ...this.$route.query,
      };

      if (this.address) {
        newQuery.destAddress = encodeURI(this.address);
      } else {
        delete newQuery.destAddress;
      }

      if (this.lat) {
        newQuery.destLat = this.lat.toString();
      } else {
        delete newQuery.destLat;
      }

      if (this.lon) {
        newQuery.destLon = this.lon.toString();
      } else {
        delete newQuery.destLon;
      }

      if (this.name) {
        newQuery.destName = this.name;
      } else {
        delete newQuery.destName;
      }

      this.$router.replace({
        query: newQuery,
      });
    },
    chargerIsWithIn1Km({
      charger,
      lat,
      lon,
    }: {
      charger: Charger;
      lat: number;
      lon: number;
    }): boolean {
      // bail out early if charger coordinates are default null island.
      if (charger.isNullIsland) return false;
      // calculate distance from charger in km.
      const distanceFromWaypoint = haversineDistance(
        charger.coordinates.asLngLat,
        [lon, lat]
      );
      // return weather charger is with in 1km.
      return distanceFromWaypoint <= 1;
    },
  },
  mounted() {
    this.address = queryValueToString(this.$route.query.destAddress);
    this.lat = queryValueToNumber(this.$route.query.destLat);
    this.lon = queryValueToNumber(this.$route.query.destLon);
    this.name = queryValueToString(this.$route.query.destName);
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripAddStops ||
        to.name === RouteNames.tripAddDetails ||
        to.name === RouteNames.tripAddOrigin ||
        to.name === RouteNames.tripAddDestination ||
        to.name === RouteNames.tripStats) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
});
</script>

<template>
  <v-container v-if="charger" class="pa-5">
    <v-container class="pt-0">
      <v-card-title class="pt-0 pl-0">
        {{ charger.name }}

        <!-- conditionally rendered Network logos -->
        <v-spacer v-if="charger.operator && charger.operator.name"></v-spacer>
        <OperatorLogo :networkBranding="branding" />
      </v-card-title>
      <v-card-subtitle v-if="charger.addressString" class="pl-0">
        {{ charger.addressString }}
      </v-card-subtitle>
      <v-card-subtitle v-else>
        charging station address has not been provided
      </v-card-subtitle>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="primary--text">
            Charging station devices
            <template v-slot:actions>
              <v-icon color="primary" large>mdi-menu-down</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <EvseCard
              :evses="charger.evses"
              @selectConnector="selectConnector"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actual charging stop info card -->
      <ChargingStopDetailsCard
        v-if="
          trip &&
          charger &&
          trip.status !== 'optimised-trip' &&
          trip.chargingStopCDBIDs.includes(charger.id)
        "
      />
      <!-- Estimated charging stop info card -->
      <ChargingCalculatorCard
        v-else-if="charger && vehicle && vehicle.evModel"
        :connector="selectedConnector"
      />
      <!-- no EV model set error display -->
      <v-card class="pa-2 mt-5 mb-5 rounded-lg" v-else>
        <v-card-text>
          Please set an EV profile for your EV to see estimates.
        </v-card-text>
      </v-card>
      <!-- add charger section -->
      <v-card v-if="showAddChargingStopButton" class="mb-5 rounded-lg">
        <v-card-title> Add charger to your trip </v-card-title>
        <v-card-text>
          <v-slider
            v-model="chargeTo"
            min="1"
            max="100"
            step="1"
            track-color="grey lighten-2"
            :label="'Charge up to ' + chargeTo + '%'"
          />
          <v-list class="background">
            <v-subheader>Add to leg</v-subheader>
            <v-list-item-group v-model="selectedStep" color="primary" mandatory>
              <v-list-item v-for="(item, i) in stepOrder" :key="i">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.displayName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <ElevatedBlockBtn class="mt-5" @click="addChargingStop">
            Add charging stop
          </ElevatedBlockBtn>
        </v-card-text>
      </v-card>
      <v-card flat class="pb-10">
        <v-card-title class="primary--text"> Plugs and sockets </v-card-title>
        <PlugsCard :plugs="charger.allConnectors" />
        <v-card-title class="primary--text">Photos added</v-card-title>
        <ChargingStationImageCarousel />
        <ChargingStationsAnimatedTabsCard
          :networkDisplayName="
            branding && branding.displayName ? branding.displayName : undefined
          "
          :instructions="
            branding && branding.instructions
              ? branding.instructions
              : undefined
          "
        />
      </v-card>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import PlugsCard from "./PlugsCard.vue";
import ChargingCalculatorCard from "./ChargingCalculatorCard.vue";
import ChargingStopDetailsCard from "./ChargingStopDetailsCard.vue";
import ChargingStationImageCarousel from "./ChargingStationImageCarousel.vue";
import OperatorLogo from "../common/OperatorLogo.vue";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import EvseCard from "./EvseCard.vue";
import Connector from "@/logic/classes/charger_classes/connector";
import Charger from "@/logic/classes/charger_classes/charger";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import ChargingStationsAnimatedTabsCard from "./ChargingStationsAnimatedTabsCard.vue";
import ElevatedBlockBtn from "@/ui/components/ui-elements/buttons/ElevatedBlockBtn.vue";
import Trip from "@/logic/classes/trip_classes/trip";

/** `Vue Component:` renders expanded details of individual charging stations,
 * intended to be used with in the `SecondaryDialog` and `MobileDialog`
 * components */
export default Vue.extend({
  name: "ChargingStationDetailsContent",
  computed: {
    selectedConnector(): Connector | undefined {
      if (this.selectedConnectorId) {
        return (
          this.$store.getters[GettersTypes.selectedChargerData] as
            | Charger
            | undefined
        )?.allConnectors.find(
          (connector) => connector.id === this.selectedConnectorId
        );
      }
      return undefined;
    },
    ...mapGetters({
      charger: GettersTypes.selectedChargerData,
      branding: GettersTypes.selectedChargerBranding,
      trip: GettersTypes.selectedTripData,
      vehicle: GettersTypes.selectedVehicleData,
    }),
    showAddChargingStopButton(): boolean {
      return (
        this.charger &&
        this.trip &&
        !this.trip.itinerary.chargerIDs.includes(this.charger.id) &&
        this.vehicle &&
        this.charger.isCompatible(this.vehicle) !== "incompatible"
      );
    },
    ...mapState({
      chargingCostPerMin: (state: unknown): number =>
        (state as State).defaultCostPerMinDC,
      publicCostPerKWh: (state: unknown): number =>
        (state as State).defaultPublicCostPerKWh,
    }),
    stepOrder(): {
      distanceScore: number;
      stepIndex: number;
      pointInBounds: boolean;
      displayName: string;
    }[] {
      if (!this.trip) return [];
      if (!this.charger) return [];
      return (this.trip as Trip).itinerary.orderStepsByDistanceFromPoint({
        lat: this.charger.coordinates.latitude,
        lon: this.charger.coordinates.longitude,
      });
    },
  },
  data() {
    return {
      value: 68,
      tab: 0,
      localRange: null,
      isBookable: false,
      selectedConnectorId: undefined as string | undefined,
      chargeTo: 80,
      selectedStep: 0,
      fetching: false,
    };
  },
  methods: {
    close() {
      this.$store.commit(MutationTypes.setSelectedCharger, undefined);
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
    },
    selectConnector(uuid: string): void {
      this.selectedConnectorId = uuid;
    },
    setInitialValues(): void {
      const vehicle: Vehicle | undefined = this.vehicle;
      const charger: Charger | undefined = this.charger;
      if (vehicle && charger) {
        const connectors = charger.allConnectors.filter(
          (connector) => connector.isCompatible(vehicle) !== "incompatible"
        );
        if (connectors.length) this.selectConnector(connectors[0].id);
      } else if (charger) {
        this.selectConnector(charger.evses[0].connectors[0].id);
      }
    },
    addChargingStop(): void {
      if (!this.charger) return;
      if (!this.trip) return;
      (this.trip as Trip).addChargerToStep({
        stepIndex: this.stepOrder[this.selectedStep].stepIndex,
        charger: this.charger,
        stateOfChargeAfterCharging: this.chargeTo / 100,
        defaultCostPerKWh: this.publicCostPerKWh,
        defaultCostPerMinDC: this.chargingCostPerMin,
      });
      this.$store.commit(MutationTypes.updateIndividualTrip, this.trip);
      this.close();
    },
    async fetchCorridorCall(): Promise<void> {
      this.fetching = true;
      const typedTrip = this.trip as Trip | undefined;
      if (!typedTrip) return;
      const outcome = await typedTrip.getRadarData();
      if (outcome === "SUCCESS") {
        this.$store.commit(MutationTypes.updateIndividualTrip, typedTrip);
      }
      this.fetching = false;
    },
  },
  watch: {
    trip() {
      if (this.trip) {
        this.fetchCorridorCall();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValues();
    });
  },
  components: {
    OperatorLogo,
    PlugsCard,
    ChargingCalculatorCard,
    ChargingStopDetailsCard,
    ChargingStationImageCarousel,
    EvseCard,
    ChargingStationsAnimatedTabsCard,
    ElevatedBlockBtn,
  },
});
</script>

<style scoped>
#pwt-custom-active-tab {
  border-radius: 10px 10px 0 0;
  background-color: var(--v-primary-base);
}

.pwt-tabs-border {
  border-bottom: 2px solid var(--v-primary-base);
}

.pwt-custom-active-tab-text {
  z-index: 1500;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>

import { render, staticRenderFns } from "./DefaultVehicleCard.vue?vue&type=template&id=78c77e28&scoped=true"
import script from "./DefaultVehicleCard.vue?vue&type=script&lang=ts"
export * from "./DefaultVehicleCard.vue?vue&type=script&lang=ts"
import style0 from "./DefaultVehicleCard.vue?vue&type=style&index=0&id=78c77e28&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78c77e28",
  null
  
)

export default component.exports
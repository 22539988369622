<template>
  <v-card>
    <v-card-title> Arrive in {{ destination.name }} </v-card-title>
    <v-card-subtitle> {{ destination.addressStr }} </v-card-subtitle>
    <v-card-text>
      <v-slider
        :label="
          'Arrive with ' + Math.round(destination.arrivalCharge * 100) + '%'
        "
        :value="Math.round(destination.arrivalCharge * 100)"
        track-color="grey lighten-2"
        readonly
      ></v-slider>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { ItineraryDestination } from "@/logic/classes/trip_classes/itinerary";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ItineraryDestinationCard",
  props: {
    destination: {
      type: Object as PropType<ItineraryDestination>,
      required: true,
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.planning)?_c('InfoPanelCardWrapper',[_c('DescriptiveLoader',{attrs:{"message":"Almost there!"}})],1):_c('InfoPanelCardWrapper',{staticStyle:{"padding-bottom":"100px !important"}},[_c('v-row',{staticClass:"flex-nowrap",attrs:{"align":"center","no-gutters":""}},[_c('TripsHomeBtn'),_c('v-breadcrumbs',{staticClass:"flex-md-nowrap pl-2",staticStyle:{"max-width":"90%"},attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{staticClass:"text-truncate",attrs:{"to":item.to,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1),_c('LocationsListRow',{attrs:{"addressData":_vm.originAddressData,"id":"origin","type":"origin","loading":_vm.addressLoading,"readonly":true},on:{"update":_vm.updateAddress}}),_vm._l((_vm.waypoints),function(stop){return _c('LocationsListRow',{key:stop.local_id,attrs:{"addressData":{
      address: stop.address,
      ...stop.coordinates.asCapitalizedObj,
    },"id":stop.local_id,"type":"stop","loading":_vm.addressLoading,"errorMsg":_vm.addressErrors,"readonly":"","appendIcon":"mdi-close"},on:{"update":_vm.updateAddress,"append":function($event){return _vm.handleRemoveWaypoint(stop.local_id)},"click":function($event){return _vm.handleShowEditWaypoint(stop)}}})}),_c('LocationsListRow',{attrs:{"addressData":_vm.destinationAddressData,"id":"destination","type":"destination","loading":_vm.addressLoading,"readonly":true},on:{"update":_vm.updateAddress}}),_c('v-card-title',{staticClass:"px-0 d-flex align-start flex-column flex-md-row"},[_vm._v(" Add Stops "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('OutlinedBtn',{on:{"click":function($event){_vm.addSavedLocationsDialogActivator = true}}},[_vm._v(" Add stops from saved locations ")]):_c('OutlinedBlockBtn',{staticClass:"mt-2",on:{"click":function($event){_vm.addSavedLocationsDialogActivator = true}}},[_vm._v(" Add stops from saved locations ")])],1),_c('WaypointPlanningCard',{attrs:{"remainingLoad":_vm.remainingLoad,"originCoordinates":_vm.originAddressData
        ? {
            lat: _vm.originAddressData.coordinates.Latitude,
            lon: _vm.originAddressData.coordinates.Longitude,
          }
        : { lat: 0, lon: 0 },"destinationCoordinates":_vm.destinationAddressData
        ? {
            lat: _vm.destinationAddressData.coordinates.Latitude,
            lon: _vm.destinationAddressData.coordinates.Longitude,
          }
        : { lat: 0, lon: 0 }},on:{"add-stop":_vm.handleAddWaypoint}}),_c('div',{staticClass:"px-5",class:_vm.classes,staticStyle:{"position":"fixed","top":"calc(100dvh - 50px)","left":"0","width":"40%","max-width":"600px"},style:(_vm.$vuetify.breakpoint.smAndDown ? 'width: 100%;' : '')},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"5","cols-md":"4"}},[_c('div',{staticClass:"background rounded-lg"},[_c('ElevatedBlockBtn',{attrs:{"color":"#dadada","darkText":"","disabled":_vm.waypoints.length < 2 ? true : _vm.preventOptimising},on:{"click":_vm.handleOptimiseStops}},[_vm._v(" Optimise stops ")])],1)]),_c('v-col',{staticClass:"pl-1",attrs:{"cols":"7","cols-md":"8"}},[_c('div',{staticClass:"background rounded-lg"},[_c('ElevatedBlockBtn',{staticClass:"mb-3",attrs:{"disabled":_vm.preventPlaning || _vm.addressLoading,"loading":_vm.addressLoading},on:{"click":_vm.handleCreateTrip}},[_vm._v(" Select a vehicle ")])],1)])],1)],1),_c('GenericDialog',{attrs:{"activator":_vm.showStopEditDialog},on:{"close":function($event){_vm.showStopEditDialog = false}}},[_c('WaypointEditForm',{attrs:{"remainingLoad":_vm.remainingLoad,"initialValue":_vm.editingStop},on:{"update":_vm.handleUpdateWaypoint}})],1),_c('AddSavedLocations',{attrs:{"activator":_vm.addSavedLocationsDialogActivator},on:{"close":function($event){_vm.addSavedLocationsDialogActivator = false},"addStops":_vm.handleAddMultipleWaypoints}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./VehicleDetails.vue?vue&type=template&id=3dcf5fec&scoped=true"
import script from "./VehicleDetails.vue?vue&type=script&lang=ts"
export * from "./VehicleDetails.vue?vue&type=script&lang=ts"
import style0 from "./VehicleDetails.vue?vue&type=style&index=0&id=3dcf5fec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dcf5fec",
  null
  
)

export default component.exports
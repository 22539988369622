<template>
  <InfoPanelCardWrapper>
    <v-card flat>
      <v-card-title class="tertiary--text pt-5">
        <BackBtn />
        Favourite Trips
      </v-card-title>
      <FrequentTripSavings />
      <v-card-text>
        <v-card
          flat
          v-if="fetchingTrips"
          class="d-flex justify-center align-center"
        >
          <LoadingCard />
        </v-card>
        <template v-else>
          <StoredTripsGroupCard
            v-for="(group, groupIndex) in savedTripsGroupedByEV"
            :key="`saved-trip-group-${groupIndex}`"
            :groupedTrips="group"
          />
          <v-card v-if="!savedTripsGroupedByEV.length" flat>
            <v-card-text class="pt-0">
              You currently have no saved trips.
            </v-card-text>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import InfoPanelCardWrapper from "@/ui/components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { mapState } from "vuex";
import { SortedTripsGroup } from "@/logic/types/sheared_local_types";
import { State } from "@/logic/store/store_types";
import hardcodedStandardEfficiencyBackup from "@/logic/data/standardEfficiencyDefaultData";
import StoredTripsGroupCard from "@/ui/components/trips/history/StoredTripsGroupCard.vue";
import FrequentTripSavings from "@/ui/components/trips/statistics/info-panel-content/FrequentTripSavings.vue";
import LoadingCard from "@/ui/components/ui-elements/loaders/LoadingCard.vue";
import BackBtn from "@/ui/components/ui-elements/buttons/BackBtn.vue";

export default Vue.extend({
  name: "HistorySavedView",
  components: {
    InfoPanelCardWrapper,
    StoredTripsGroupCard,
    FrequentTripSavings,
    LoadingCard,
    BackBtn,
  },
  methods: {
    // Routing
    back() {
      this.$router.back();
    },
    pushRoute(routeName: RouteNames) {
      this.$router.push({ name: routeName });
    },
  },
  computed: {
    ...mapState({
      savedTripsGroupedByEV: (state: unknown): SortedTripsGroup[] => {
        const typedState = state as State;
        const trips = typedState.trips
          .filter((trip) => !!trip.external_id)
          .reverse();
        const returnArray: SortedTripsGroup[] = [];
        const usedEVs: string[] = [];
        const unsortedGroup: SortedTripsGroup = {
          evModel: "unknown",
          trips: [],
        };
        trips.forEach((trip) => {
          let modelID: string | number | undefined =
            trip.vehicle_data?.eVModelId;
          if (!modelID) unsortedGroup.trips.push(trip);

          if (typeof modelID === "number") {
            // ASSUMES: if number is a legacy ID and needs converting.
            modelID = hardcodedStandardEfficiencyBackup.find(
              (model) => model.legacyId === modelID
            )?.id;
          }
          if (modelID && usedEVs.includes(modelID)) {
            const index = returnArray.findIndex(
              (group) => group.evModel === (modelID as string)
            );
            if (index !== -1) {
              returnArray[index].trips.push(trip);
            } else {
              returnArray.push({
                evModel: modelID,
                trips: [trip],
              });
            }
          } else if (modelID) {
            returnArray.push({
              evModel: modelID,
              trips: [trip],
            });
            usedEVs.push(modelID);
          }
        });
        if (unsortedGroup.trips.length) returnArray.push(unsortedGroup);
        return returnArray;
      },
      fetchingTrips: (state: unknown): boolean =>
        (state as State).tripFetchingFlag,
    }),
  },
});
</script>

import { render, staticRenderFns } from "./PlanningAddOriginView.vue?vue&type=template&id=295bede8&scoped=true"
import script from "./PlanningAddOriginView.vue?vue&type=script&lang=ts"
export * from "./PlanningAddOriginView.vue?vue&type=script&lang=ts"
import style0 from "./PlanningAddOriginView.vue?vue&type=style&index=0&id=295bede8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295bede8",
  null
  
)

export default component.exports
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

export enum RouteNames {
  home = "home",
  trips = "trips",
  saved = "saved",
  unsaved = "unsaved",
  tripAddDestination = "tripAddDestination",
  tripAddOrigin = "tripAddOrigin",
  tripAddDetails = "tripAddDetails",
  tripAddStops = "tripAddStops",
  tripItinerary = "tripItinerary",
  tripStats = "tripStats",
}

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: RouteNames.home,
    redirect: "/trips",
  },
  {
    path: "/trips",
    component: () => import("@/ui/views/NestedRouteWrapper.vue"),
    children: [
      {
        path: "",
        name: RouteNames.trips,
        component: () => import("@/ui/views/HistoryMainView.vue"),
      },
      {
        path: "saved",
        name: RouteNames.saved,
        component: () => import("@/ui/views/HistorySavedView.vue"),
      },
      {
        path: "unsaved",
        name: RouteNames.unsaved,
        component: () => import("@/ui/views/HistoryUnsavedView.vue"),
      },
    ],
  },
  {
    path: "/trip/v2",
    component: () => import("@/ui/views/NestedRouteWrapper.vue"),
    children: [
      {
        path: "add-destination",
        name: RouteNames.tripAddDestination,
        component: () => import("@/ui/views/PlanningAddDestinationView.vue"),
      },
      {
        path: "add-origin",
        name: RouteNames.tripAddOrigin,
        component: () => import("@/ui/views/PlanningAddOriginView.vue"),
      },
      {
        path: "add-details",
        name: RouteNames.tripAddDetails,
        component: () => import("@/ui/views/PlanningAddDetailsView.vue"),
      },
      {
        path: "add-stops",
        name: RouteNames.tripAddStops,
        component: () => import("@/ui/views/PlanningAddStopsView.vue"),
      },
      {
        path: "itinerary",
        name: RouteNames.tripItinerary,
        component: () => import("@/ui/views/PlanningItineraryView.vue"),
      },
      {
        path: "trip-stats",
        name: RouteNames.tripStats,
        component: () => import("@/ui/views/TripStatsView.vue"),
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import("@/ui/components/info-panel/PageNotFoundContent.vue"),
  },
];

const basename = (function () {
  return window.location.pathname;
})();

const router = new VueRouter({
  // mode: "history",
  base: basename,
  routes,
});

export default router;
